import React, { CSSProperties } from "react";
import { Button, Popconfirm } from "antd";
import Settings from "./Settings";
import { SizeType } from "antd/lib/config-provider/SizeContext";

class UiUtil {
	static isAdministrator(session: any): boolean {
		const groups: string[] = session.idToken.payload["cognito:groups"];
		return groups.includes("CodeCarpenterAdministrator");
	}

	static preventClickPropagation(e: React.MouseEvent) {
		e.stopPropagation();
	}

	static async awaitTimeout(delayMillis: number) {
		const doTimeout = () =>
			new Promise((resolve) => setTimeout(resolve, delayMillis));
		await doTimeout();
	}

	static createIcon(config: CreateIconConfig) {
		if (config.isDisabled) {
			return <></>;
		}
		const selectable = !!config.disableTooltip;
		const tooltip = !config.isSelected
			? config.tooltip
			: config.disableTooltip || config.tooltip;
		const style: CSSProperties = {
			marginLeft:
				config.leftSpacing || config.isSelected
					? Settings.cssMediumSize
					: Settings.cssSmallSize,
			marginRight:
				config.leftSpacing || config.isSelected ? Settings.cssSmallSize : null,
			opacity: config.isDangerIcon ? Settings.deleteDraftIconOpacity : ".8",
			transform: config.isSelected && "scale(1.3)",
			transition: "all",
		};
		const button = (
			<Button
				title={tooltip}
				key={tooltip}
				icon={config.icon}
				danger={config.isSelected || config.isDangerIcon}
				ghost={config.isGhostIcon}
				type={selectable && !config.isSelected ? "dashed" : "default"}
				size={config.size || "small"}
				shape="circle"
				style={style}
				onClick={!config.requireConfirmation ? config.onClick : () => null}
			/>
		);
		if (config.requireConfirmation) {
			return (
				<Popconfirm
					title={config.confirmationMessage || "Are you sure?"}
					key={tooltip}
					placement="bottom"
					onConfirm={config.onClick}
					okText={config.tooltip}
				>
					{button}
				</Popconfirm>
			);
		} else {
			return button;
		}
	}
}

export interface CreateIconConfig {
	tooltip: string;
	disableTooltip?: string;
	icon: React.ReactNode;
	size?: SizeType;
	leftSpacing?: boolean;
	requireConfirmation?: boolean;
	confirmationMessage?: string;
	isSelected?: boolean;
	isDangerIcon?: boolean;
	isGhostIcon?: boolean;
	isDisabled?: boolean;
	onClick: () => void;
}

export default UiUtil;
