import { Layout } from "antd";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import Header from "./header/Header";
import ProfilePage from "./profile-page/ProfilePage";
import ProjectsPage from "./projects-page/ProjectsPage";
import Tree from "./tree/Tree";
import ThemeAdminPage from "./admin/themes/ThemeAdminPage";
import { useDispatch } from "react-redux";
import UrlUtil from "../ui-util/UrlUtil";

const PageLayout = styled(Layout)`
	height: 100vh;
	background: url("/images/Moraine_Lake_Alberta_Canada_Medium.jpg") no-repeat;
	background-size: cover;
`;

const PageContent = styled(Layout.Content)`
	overflow: auto;
`;

const Home = () => {
	const dispatch = useDispatch();

	// Runs only once (using empty array from https://itnext.io/add-state-and-lifecycle-methods-to-function-components-with-react-hooks-8e2bdc44d43d)
	useEffect(() => UrlUtil.loadBasicData(dispatch), [dispatch]);

	return (
		<Router>
			<PageLayout>
				<Header />
				<PageContent id="pageContent">
					<Switch>
						<Route path="/" exact component={Tree} />
						<Route path="/projects/" exact component={ProjectsPage} />
						<Route path="/projects/create" exact component={ProjectsPage} />
						<Route path="/profile/" component={ProfilePage} />
						<Route path="/admin/themes" component={ThemeAdminPage} />
						<Route path="/projects/:projectName" component={Tree} />
					</Switch>
				</PageContent>
			</PageLayout>
		</Router>
	);
};
export default Home;
