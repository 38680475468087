import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { devToolsEnhancer } from "redux-devtools-extension";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import rootReducer from "./store/reducers";

// For redux devtools (see https://github.com/zalmoxisus/redux-devtools-extension#13-use-redux-devtools-extension-package-from-npm)
const store = createStore(
	rootReducer,
	/* preloadedState, */ devToolsEnhancer({
		// Specify name here, actionsBlacklist, actionsCreators and other options if needed
	})
);

const application = (
	<Provider store={store}>
		<App />
	</Provider>
);

ReactDOM.render(application, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
