import { TaskType } from "../../graphql-util/TaskType";
import { TaskInState } from "../reducers/tasks";

export enum TaskActionNames {
	SetLoadingTasks = "SetLoadingTasks",
	SetTaskTypes = "SetTaskTypes",
	SetTasksForProject = "SetTasksForProject",
	AppendTasksForProject = "AppendTasksForProject",
	AddOrUpdateTask = "AddOrUpdateTask",
	SetMostRecentlyHoveredLeaf = "SetMostRecentlyHoveredLeaf",
	RemoveTask = "RemoveTask",
}

export const setLoadingTasks = (isLoading: boolean) => ({
	type: TaskActionNames.SetLoadingTasks,
	isLoading,
});

export const setTaskTypes = (taskTypes: TaskType[]) => ({
	type: TaskActionNames.SetTaskTypes,
	taskTypes,
});

export const setTasksForProject = (
	projectId: string,
	tasks: TaskInState[]
) => ({
	type: TaskActionNames.SetTasksForProject,
	projectId,
	tasks,
});

export const appendTasksForProject = (
	projectId: string,
	tasks: TaskInState[]
) => ({
	type: TaskActionNames.AppendTasksForProject,
	projectId,
	tasks,
});

export const addOrUpdateTask = (
	task: TaskInState,
	taskIdOverride?: string
) => ({
	type: TaskActionNames.AddOrUpdateTask,
	task,
	taskIdOverride,
});

export const setMostRecentlyHoveredLeaf = (taskId: string | null) => ({
	type: TaskActionNames.SetMostRecentlyHoveredLeaf,
	taskId,
});

export const removeTask = (task: TaskInState) => ({
	type: TaskActionNames.RemoveTask,
	task,
});
