import React, { CSSProperties } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Tasks from "../../../graphql-util/Tasks";
import { TaskInState } from "../../../store/reducers/tasks";
import Settings from "../../../ui-util/Settings";
import { RowAlignedCenter } from "../../../ui-util/StyledComponents";
import CompletionIcon from "../common/CompletionIcon";
import SubTaskMenuIcons from "./SubTaskMenuIcons";
import TitleOrDescription from "../common/TitleOrDescription";

type SubTaskProps = {
	task: TaskInState;
};
const SubTask = (props: SubTaskProps) => {
	const dispatch = useDispatch();

	const showTitle = !props.task.description;
	const isHovered = props.task.isHovered;
	const style: CSSProperties = {
		backgroundColor: isHovered
			? "rgba(80, 80, 80, .05)"
			: "rgba(80, 80, 80, .17)",
	};
	return (
		<StyledSubTask
			className="leaf-sub-task margin-left-small margin-top-small"
			style={style}
			onMouseEnter={() => Tasks.updateHoverState(true, props.task, dispatch)}
			onMouseLeave={() => Tasks.updateHoverState(false, props.task, dispatch)}
		>
			<CompletionIcon task={props.task} />

			<div className="flex-grow overflow-auto" style={{ marginLeft: "10px" }}>
				<TitleOrDescription isTitle={showTitle} task={props.task} />
			</div>

			<SubTaskMenuIcons task={props.task} />
		</StyledSubTask>
	);
};

const StyledSubTask = styled(RowAlignedCenter)`
	border-radius: 15px;
	padding: 3px 12px;
	box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.5);
	transition: background-color ${Settings.quickAnimationTime};
`;

export default SubTask;
