import { Draggable, DroppableProvided } from "react-beautiful-dnd";
import Tasks from "../../../graphql-util/Tasks";
import SubTask from "./SubTask";
import React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../../store/reducers";
import { TaskInState } from "../../../store/reducers/tasks";

type SubTaskProps = {
	task: TaskInState;
	droppableProvided: DroppableProvided
};
export const SubTasks = (props: SubTaskProps) => {
	const subTasks = useSelector((state: StoreState) => {
		const pastTasksAsOfDate = state.settings.pastTasksAsOfDate;
		return Tasks.getDirectChildTasks(
			props.task,
			state,
			true,
			pastTasksAsOfDate
		);
	});

	return (
		<div>
			{subTasks.map((subTask, index) => (
				<Draggable
					key={Tasks.getKey(subTask)}
					draggableId={Tasks.getKey(subTask)}
					index={index}
				>
					{(draggableProvided) => (
						<div
							ref={draggableProvided.innerRef}
							{...draggableProvided.draggableProps}
							{...draggableProvided.dragHandleProps}
							style={draggableProvided.draggableProps.style}
						>
							<SubTask key={Tasks.getKey(subTask)} task={subTask}/>
						</div>
					)}
				</Draggable>
			))}
			{props.droppableProvided.placeholder}
		</div>
	);
};
