import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";

type InfoIconProps = {
	title: string;
	className?: string;
};
export const InfoIcon = (props: InfoIconProps) => {
	return (
		<Tooltip
			title={props.title}
			className={(props.className || "") + " info-icon"}
			placement="right"
		>
			<InfoCircleOutlined />
		</Tooltip>
	);
};
