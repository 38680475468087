import { Dropdown, Menu } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { StoreState } from "../../store/reducers";
import Settings from "../../ui-util/Settings";
import LinkToProject from "../LinkToProject";
import {
	PlusOutlined,
	SettingOutlined,
	StarOutlined,
	StarTwoTone,
} from "@ant-design/icons";
import { ProjectInState } from "../../store/reducers/projects";
import ProjectApi from "../../graphql-util/ProjectApi";
import { UpdateProjectInput } from "../../API";

const ProjectTitle = styled.a`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;

	font-weight: bold;
	min-width: 70px;
`;

const ProjectSelector = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { projects, selectedProjectName } = useSelector((state: StoreState) => {
		const selectedProjectId = state.projects.selectedProjectId;
		const allProjects = state.projects.projectList;
		const selectedProject =
			selectedProjectId &&
			allProjects.find((project) => project.id === selectedProjectId);
		return {
			projects: [...allProjects].sort((a, b) => {
				if (a.isFavorite && !b.isFavorite) {
					return -1;
				} else if (!a.isFavorite && b.isFavorite) {
					return 1;
				} else {
					return a.name.localeCompare(b.name);
				}
			}),
			selectedProjectName: (selectedProject && selectedProject.name) || null,
		};
	});
	const [hoveredProject, setHoveredProject] = useState(
		null as ProjectInState | null
	);

	const toggleFavorite = (project: ProjectInState) => {
		const input: UpdateProjectInput = {
			id: project.id as string,
			isFavorite: !project.isFavorite,
			expectedVersion: project.version as number,
		};
		ProjectApi.updateProject(input, dispatch);
	};

	return (
		<Dropdown
			overlay={
				<Menu>
					<Menu.Item
						key="manage"
						icon={<SettingOutlined />}
						onClick={() => history.push("/projects/")}
					>
						Projects
					</Menu.Item>
					<Menu.Item
						key="create"
						icon={<PlusOutlined />}
						onClick={() => history.push("/projects/create")}
					>
						New...
					</Menu.Item>
					<Menu.Divider />
					{projects.map((project) => {
						const favoriteIcon = project.isFavorite ? (
							<StarTwoTone
								twoToneColor="goldenrod"
								onClick={() => toggleFavorite(project)}
							/>
						) : (
							<StarOutlined
								style={{ opacity: project === hoveredProject ? 0.2 : 0 }}
								onClick={() => toggleFavorite(project)}
							/>
						);
						return (
							<Menu.Item
								key={project.id as string}
								icon={favoriteIcon}
								onMouseEnter={() => setHoveredProject(project)}
								onMouseLeave={() => setHoveredProject(null)}
							>
								<LinkToProject projectName={project.name} />
							</Menu.Item>
						);
					})}
				</Menu>
			}
			trigger={["click"]}
		>
			<ProjectTitle
				className="ant-dropdown-link"
				href="/#"
				style={{ maxHeight: Settings.headerHeight }}
			>
				{selectedProjectName || "Select a project"}
			</ProjectTitle>
		</Dropdown>
	);
};
export default ProjectSelector;
