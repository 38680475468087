import styled from "styled-components";
import { Auth } from "aws-amplify";
import React from "react";
import { Button, Layout } from "antd";
import {
	ColumnAlignedCenter,
	DarkPagePanel,
	FlexRow,
	FlexRowWrapped,
} from "../ui-util/StyledComponents";

const Homepage = () => {
	return (
		<HomepageLayout>
			<NavBar>
				<Button type="primary" onClick={() => Auth.federatedSignIn()}>
					Sign In
				</Button>
			</NavBar>

			<TitleSection>
				<Title>Code Carpenter</Title>
				<SubTitle>Task management for programmers</SubTitle>
			</TitleSection>

			<ColumnAlignedCenter style={{ marginBottom: "25px" }}>
				<HomepageSection style={{ marginTop: 0 }}>
					<FlexRow style={{ justifyContent: "space-around" }}>
						<div>
							<h2>A to-do list for your workflow</h2>

							<h4>Getting started</h4>
							<ul>
								<li>As you develop, create tasks which appear as leaves</li>
								<li>Add sub-tasks to leaves</li>
								<li>
									When sub-tasks become significant, expand them out into their
									own leaves
								</li>
							</ul>

							<h4>Features</h4>
							<ul>
								<li>
									Collapse leaves to hide tasks that you will work on later
								</li>
								<li>Designate urgent and favorite tasks</li>
							</ul>

							<h4>Cloud technology</h4>
							<ul>
								<li>Your data and files are stored security in AWS</li>
							</ul>
						</div>
						<img
							src="/images/homepage/preview-screenshot.png"
							alt="Screenshot"
							width={500}
							style={{ alignSelf: "center" }}
						/>
					</FlexRow>
				</HomepageSection>

				<HomepageSection style={{ width: "60%", alignSelf: "center" }}>
					<h2 style={{ textAlign: "center" }}>Technologies used</h2>
					<TechnologyList>
						<div style={{ color: "#4CC8ED" }}>
							<img src="/images/homepage/react-logo.png" alt="React" />
							React
						</div>
						<div style={{ color: "rgba(118,73,187,0.87)" }}>
							<img src="/images/homepage/redux.png" alt="Redux" />
							Redux
						</div>
						<div>TypeScript</div>
						<div style={{ color: "#656565" }}>
							<img src="/images/homepage/ant-design.png" alt="GraphQL" />
							Ant Design
						</div>
						<div style={{ color: "rgb(219, 112, 147)" }}>styled-components</div>
						<img src="/images/homepage/aws.png" alt="AWS" width={150} />
						<div style={{ color: "orange" }}>AWS Amplify</div>
						<div style={{ color: "rgba(229,53,171,0.76)" }}>
							<img src="/images/homepage/graphql.png" alt="GraphQL" />
							GraphQL
						</div>
						<div style={{ color: "rgba(34,114,186,0.77)" }}>
							<img src="/images/homepage/DynamoDB.png" alt="DynamoDB" />
							DynamoDB
						</div>
					</TechnologyList>
				</HomepageSection>
			</ColumnAlignedCenter>
		</HomepageLayout>
	);
};

const silverColor = "#e3e3e3";

const HomepageLayout = styled(Layout)`
	height: 100vh;
	background-image: url("/images/Moraine_Lake_Alberta_Canada_Medium.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	// In case the page loads before the background image is available
	background-color: #2246a7;
	font-size: 1.25em;
	overflow-y: auto;
`;

const NavBar = styled(Layout.Header)`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const TitleSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 225px;
	color: white;
	text-shadow: 2px 2px rgba(4, 4, 4, 0.82);
`;

const Title = styled.div`
	font-size: 2.5em;
	font-weight: bolder;
`;
const SubTitle = styled.div`
	color: ${silverColor};
	font-size: 1.25em;
	font-style: italic;
`;

const HomepageSection = styled(DarkPagePanel)`
	align-self: center;
	color: ${silverColor};
	margin: 25px;
	width: 80%;
	min-height: unset;
	text-shadow: 2px 2px rgba(4, 4, 4, 0.82);
`;

const TechnologyList = styled(FlexRowWrapped)`
	justify-content: center;

	& > img,
	& > div {
		margin: 15px;
		background-color: rgba(255, 255, 255, 0.13);
		border-radius: 5px;
		border: 1px solid #393939;
		padding: 10px;
		box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.9);
	}

	& img {
		margin-right: 5px;
	}

	& div {
		font-size: 1.25em;
		font-weight: bold;
		font-style: italic;
	}

	& div > img {
		width: 50px;
	}
`;

export default Homepage;
