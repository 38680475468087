import { Dispatch } from "react";
import { addOrUpdateTask } from "../store/actions/tasks";
import { StoreState } from "../store/reducers";
import { TaskInState } from "../store/reducers/tasks";
import { TaskStatus } from "../API";

export default class Tasks {
	static readonly defaultCompareFn = (a: TaskInState, b: TaskInState) =>
		a.createdOn - b.createdOn;

	static getId(task: TaskInState): string {
		return task.id || (task.unsavedTaskId as string);
	}

	static getKey(task: TaskInState): string {
		return task.unsavedTaskId || task.id;
	}

	static isComplete(task: TaskInState): boolean {
		return !!task.completionDate;
	}

	static isSubTask(task: TaskInState): boolean {
		return !task.isExpanded && !!task.parentTaskId;
	}

	static createDummyTask(
		projectId: string,
		parentTaskId: string | null,
		isExpanded: boolean
	): TaskInState {
		return {
			id: null,
			projectId,
			parentTaskId,
			isExpanded,
			isFavorite: false,
			isUrgent: false,
			isHovered: false,
			taskStatus: null,
			typeId: null,
			title: null,
			description: "",
			completionDate: null,
			createdOn: new Date().getTime(),
			unsavedTaskId: Number(Math.random() * 1000000000).toString(),
			editImmediately: true,
		};
	}

	static updateHoverState(
		isHovered: boolean,
		task: TaskInState,
		dispatch: Dispatch<any>
	) {
		const updatedTask = Object.assign({}, task, { isHovered } as TaskInState);
		dispatch(addOrUpdateTask(updatedTask));
	}

	static getTask(taskId: string, allTasks: TaskInState[]): TaskInState | null {
		return allTasks.find((task) => task.id === taskId);
	}

	static getDirectChildTasks(
		parentTask: TaskInState | null,
		storeState: StoreState,
		getSubTasks: boolean,
		pastTasksAsOfDate: Date | null
	): TaskInState[] {
		const selectedProjectId = storeState.projects.selectedProjectId;
		const allTasks = storeState.tasks.allTasks;
		const parentTaskId = parentTask && Tasks.getId(parentTask);
		const results = allTasks
			.filter((task) => task.projectId === selectedProjectId)
			.filter(
				(task) =>
					(!!parentTaskId && parentTaskId === task.parentTaskId) ||
					(!parentTaskId && !task.parentTaskId)
			)
			.filter((task) => getSubTasks === Tasks.isSubTask(task))
			.filter((task) => {
				return (
					this.isVisibleBasedOnCompletion(task, pastTasksAsOfDate) ||
					this.doesTaskHaveVisibleChildren(
						task.id as string,
						allTasks,
						pastTasksAsOfDate
					)
				);
			});
		results.sort(Tasks.defaultCompareFn);
		return results;
	}

	static getAllChildren(
		taskId: string,
		allTasks: TaskInState[]
	): TaskInState[] {
		const directChildren = allTasks.filter(
			(task) => task.parentTaskId === taskId
		);
		let result = [...directChildren];
		directChildren.forEach((task) => {
			result = [...result, ...this.getAllChildren(task.id as string, allTasks)];
		});
		return result;
	}

	public static doesTaskHaveVisibleChildren(
		taskId: string,
		tasks: TaskInState[],
		pastTasksAsOfDate: Date | null = null
	): boolean {
		let parentIds: string[] = [taskId];
		while (parentIds.length > 0) {
			const parentId = parentIds.pop();
			const children = tasks.filter((task) => task.parentTaskId === parentId);
			if (
				children.find((task) => {
					return this.isVisibleBasedOnCompletion(task, pastTasksAsOfDate);
				})
			) {
				return true;
			}
			parentIds = [
				...parentIds,
				...children.map((child) => child.id as string),
			];
		}
		return false;
	}

	private static isVisibleBasedOnCompletion(
		task: TaskInState,
		pastTasksAsOfDate: Date | null
	): boolean {
		if (!task.completionDate) {
			return true;
		} else if (!pastTasksAsOfDate) {
			return false;
		} else {
			// Allow completed tasks to be shown based on the Past Tasks slider
			return new Date(task.completionDate as number) >= pastTasksAsOfDate;
		}
	}

	static isTaskOrParentCollapsed(
		task: TaskInState,
		allTasks: TaskInState[]
	): boolean {
		const tasksToCheck = this.getTaskAndParents(task, allTasks);
		return tasksToCheck.some((taskToCheck) => taskToCheck.isCollapsed);
	}

	static getTaskAndParents(
		task: TaskInState,
		allTasks: TaskInState[]
	): TaskInState[] {
		const tasks = [task];
		let currentTask = task;
		while (currentTask.parentTaskId) {
			const parent = Tasks.getTask(currentTask.parentTaskId, allTasks);
			tasks.push(parent);
			currentTask = parent;
		}
		return tasks;
	}

	static doesTaskHaveAnyChildren(
		taskId: string,
		tasks: TaskInState[]
	): boolean {
		return !!tasks.find((task) => task.parentTaskId === taskId);
	}

	static hasStatus(task: TaskInState, taskStatus: TaskStatus): boolean {
		return task.taskStatus === taskStatus && !task.completionDate;
	}
}
