import React from "react";
import { TaskInState } from "../../../store/reducers/tasks";
import { EllipsisOutlined } from "@ant-design/icons/lib";
import { useDispatch } from "react-redux";
import TaskApi from "../../../graphql-util/TaskApi";

type LeafCollapseIconProps = {
	task: TaskInState;
};

const LeafCollapsedEllipsis = (props: LeafCollapseIconProps) => {
	const dispatch = useDispatch();

	const expandTask = () => {
		TaskApi.updateTask(props.task, dispatch, { isCollapsed: false });
	};

	return (
		<EllipsisOutlined
			title="Collapsed"
			style={{ fontSize: "2.5em", color: "silver" }}
			onClick={expandTask}
		/>
	);
};
export default LeafCollapsedEllipsis;
