import { API, graphqlOperation } from "aws-amplify";
import {
	ListSharedThemesQuery,
	CreateSharedThemeInput,
	UpdateSharedThemeInput,
	UpdateSharedThemeMutation,
	DeleteSharedThemeInput,
	GetSharedThemeQuery,
} from "../API";
import { listSharedThemes, getSharedTheme } from "../graphql/queries";
import Notifications from "../ui-util/Notifications";
import {
	updateSharedTheme,
	createSharedTheme,
	deleteSharedTheme,
} from "../graphql/mutations";
import { message } from "antd";

export type SharedTheme = CreateSharedThemeInput & {
	version?: number;
};

export default class ThemeApi {
	static async loadSharedThemes(): Promise<SharedTheme[]> {
		try {
			const themes = (await API.graphql(
				graphqlOperation(listSharedThemes)
			)) as { data: ListSharedThemesQuery };
			return themes.data.listSharedThemes.items;
		} catch (e) {
			Notifications.handleError(
				`Sorry, the list of shared themes couldn't be loaded`,
				e
			);
		}
	}

	static async getSharedTheme(themeId: string): Promise<SharedTheme> {
		try {
			const themes = (await API.graphql(
				graphqlOperation(getSharedTheme, {
					id: themeId,
				})
			)) as { data: GetSharedThemeQuery };
			return themes.data.getSharedTheme;
		} catch (e) {
			Notifications.handleError(
				`Sorry, the shared theme couldn't be loaded`,
				e
			);
		}
	}

	static async createSharedTheme(theme: CreateSharedThemeInput) {
		try {
			await API.graphql(graphqlOperation(createSharedTheme, { input: theme }));
			Notifications.handleSuccess("Theme created");
		} catch (e) {
			Notifications.handleError(`Sorry, the theme couldn't be created`, e);
		}
	}

	static async updateSharedTheme(
		theme: SharedTheme,
		updateThemeInput: Partial<UpdateSharedThemeInput>
	) {
		try {
			const input: UpdateSharedThemeInput = {
				id: theme.id,
				expectedVersion: theme.version,
				...updateThemeInput,
			};
			const result = (await API.graphql(
				graphqlOperation(updateSharedTheme, { input })
			)) as { data: UpdateSharedThemeMutation };
			const updatedTheme = result.data.updateSharedTheme;
			if (!updatedTheme) {
				throw new Error("Unable to retrieve the updated theme");
			}
			Notifications.handleSuccess("Theme updated");
		} catch (e) {
			Notifications.handleError(`Sorry, the theme couldn't be updated`, e);
		}
	}

	static async deleteSharedTheme(theme: SharedTheme) {
		try {
			// Delete the project itself
			const deletionMessage = message.loading("Deleting the theme...", 0);
			try {
				const input: DeleteSharedThemeInput = {
					id: theme.id,
					expectedVersion: theme.version,
				};
				await API.graphql(graphqlOperation(deleteSharedTheme, { input }));
			} finally {
				deletionMessage();
			}

			Notifications.handleSuccess("Theme deleted");
		} catch (e) {
			Notifications.handleError(
				"Unable to delete the theme.  Please try again later.",
				e
			);
		}
	}
}
