import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Card, Input } from "antd";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router";
import { CreateProjectInput } from "../../API";
import ProjectApi from "../../graphql-util/ProjectApi";
import FormUtil from "../../ui-util/FormUtil";
import { useHistory } from "react-router-dom";
import UrlUtil from "../../ui-util/UrlUtil";

type BasicProjectFormProps = FormComponentProps<any>;

const BasicProjectForm = (props: BasicProjectFormProps) => {
	const [isSaved, setSaved] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		props.form.validateFields(); // disable the save button initially
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function saveProject(e: FormEvent<any>) {
		FormUtil.handleSubmit(props, e, async (values) => {
			const newProject: CreateProjectInput = {
				name: values.name,
			};
			await ProjectApi.createProject(newProject);
			setSaved(true);
			const projects = await ProjectApi.loadProjects(dispatch);
			if (projects.length === 1) {
				const projectName = projects[0].name;
				history.push(UrlUtil.getLinkToProject(projectName));
			}
		});
	}

	const nameError = FormUtil.getError("name", props);
	return (
		<>
			<Card title="Create a project">
				<Form onSubmit={saveProject}>
					<Form.Item
						validateStatus={nameError ? "error" : ""}
						help={nameError || ""}
					>
						{props.form.getFieldDecorator("name", {
							rules: [
								{
									required: true,
									message: "Please enter a name",
								},
							],
						})(<Input placeholder="Name" />)}
					</Form.Item>
					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							disabled={FormUtil.isFormInvalid(props)}
						>
							Save
						</Button>
						<Button
							htmlType="button"
							className="margin-left-medium"
							onClick={() => setSaved(true)}
						>
							Cancel
						</Button>
					</Form.Item>
				</Form>
			</Card>
			{isSaved && <Redirect to="/projects/" />}
		</>
	);
};

const ProjectFormAsForm = Form.create()(BasicProjectForm);
export default ProjectFormAsForm;
