import React, { useState, useEffect } from "react";
import { Input, Button, Card, Form, Spin, Checkbox } from "antd";
import { Redirect, withRouter, RouteComponentProps } from "react-router-dom";
import ThemeApi from "../../../graphql-util/ThemeApi";

type ThemeAdminFormProps = RouteComponentProps;
const ThemeAdminForm = (props: ThemeAdminFormProps) => {
	const [isSaved, setSaved] = useState(false);
	const [editedTheme, setEditedTheme] = useState(null);

	const editedThemeId = (props.match.params as any).themeId;
	useEffect(() => {
		if (editedThemeId) {
			ThemeApi.getSharedTheme(editedThemeId).then((theme) => {
				setEditedTheme(theme);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const submitForm = async (values: any) => {
		if (!editedThemeId) {
			await ThemeApi.createSharedTheme({
				name: values.name,
				isDefault: values.isDefault,
			});
			setSaved(true);
		} else {
			await ThemeApi.updateSharedTheme(editedTheme, {
				name: values.name,
				isDefault: values.isDefault,
			});
			setSaved(true);
		}
	};

	const title = editedTheme ? editedTheme.name : "Create a theme";
	const isReady = !editedThemeId || editedTheme;
	return (
		<>
			{!isReady && <Spin />}
			{isReady && (
				<Card title={title}>
					<Form initialValues={editedTheme} onFinish={submitForm}>
						<Form.Item
							label="Name"
							name="name"
							rules={[{ required: true, message: "Please enter a name" }]}
						>
							<Input placeholder="Name" />
						</Form.Item>
						<Form.Item
							label="Is Default"
							name="isDefault"
							valuePropName="checked"
						>
							<Checkbox />
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit">
								Save
							</Button>
							<Button
								htmlType="button"
								className="margin-left-medium"
								onClick={() => setSaved(true)}
							>
								Cancel
							</Button>
						</Form.Item>
					</Form>
				</Card>
			)}
			{isSaved && <Redirect to="/admin/themes" />}
		</>
	);
};
export default withRouter(ThemeAdminForm);
