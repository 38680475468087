import { InboxOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import React, { useState } from "react";
import { TaskInState } from "../../../store/reducers/tasks";
import { useDispatch } from "react-redux";
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
import { ImportExportUtil } from "../../../ui-util/ImportExportUtil";

type ModalButtonState = {
	title: string;
	isLoading: boolean;
	isDisabled: boolean;
};

type ImportDialogProps = {
	projectId: string;
	closeDialog: () => any;
};
const ImportDialog = (props: ImportDialogProps) => {
	const dispatch = useDispatch();
	const [message, setMessage] = useState("");
	const [buttonState, setButtonState] = useState({
		title: "Upload a file",
		isDisabled: true,
		isLoading: false,
	} as ModalButtonState);

	const onCustomRequest = (options: RcCustomRequestOptions) => {
		setButtonState({
			title: "Importing",
			isDisabled: true,
			isLoading: true,
		});

		const reader = new FileReader();
		reader.onload = async (ev) => {
			const result: TaskInState[] = JSON.parse(ev.target.result as string);
			try {
				await ImportExportUtil.importProject(props.projectId, result, dispatch);
				setMessage("Import complete");
			} catch (e) {
				setMessage(
					`Error during the import process; see the dev console for details.`
				);
				if (console) {
					console.error("Error during import", e);
				}
			}
			options.onSuccess(null, null);
			setButtonState({
				title: "Close",
				isDisabled: false,
				isLoading: false,
			});
		};
		reader.onerror = () => {
			setMessage("Error reading the file");
		};
		reader.readAsText(options.file as Blob, "UTF-8");
	};

	return (
		<Modal
			title="Import Data"
			visible={true}
			onCancel={props.closeDialog}
			footer={[
				<Button
					key="submit"
					type="primary"
					loading={buttonState.isLoading}
					disabled={buttonState.isDisabled}
					onClick={props.closeDialog}
				>
					{buttonState.title}
				</Button>,
			]}
		>
			<p>Upload a data export file (JSON) to add the tasks to this project.</p>

			<Dragger name="file" multiple={false} customRequest={onCustomRequest}>
				<p className="ant-upload-drag-icon">
					<InboxOutlined />
				</p>
				<p className="ant-upload-text">
					Click or drag a file here to start importing
				</p>
				<p className="ant-upload-hint">
					Leave this browser window open until compete
				</p>
			</Dragger>

			{message}
		</Modal>
	);
};
export default ImportDialog;
