import { Modal, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateProjectInput } from "../../API";
import ProjectApi from "../../graphql-util/ProjectApi";
import { StoreState } from "../../store/reducers";
import { ProjectInState } from "../../store/reducers/projects";
import TextEditor from "../../ui-util/TextEditor";

const ProjectList = () => {
	const dispatch = useDispatch();
	const { projectList, selectedProjectId } = useSelector(
		(state: StoreState) => ({
			projectList: state.projects.projectList,
			selectedProjectId: state.projects.selectedProjectId,
		})
	);

	const renameProject = (project: ProjectInState, newName: string) => {
		const input: UpdateProjectInput = {
			id: project.id as string,
			name: newName,
			expectedVersion: project.version as number,
		};
		ProjectApi.updateProject(input, dispatch);
	};

	const promptToDelete = (e: React.MouseEvent, project: ProjectInState) => {
		e.preventDefault();
		Modal.confirm({
			title: "Are you sure?",
			content: "Do you want to PERMANENTLY remove this project and its tasks?",
			okType: "danger",
			async onOk() {
				await ProjectApi.deleteProject(project, selectedProjectId, dispatch);
				loadProjects();
			},
		});
	};

	const loadProjects = async () => {
		await ProjectApi.loadProjects(dispatch);
	};

	const columns: ColumnProps<any>[] = [
		{
			title: "Name",
			key: "name",
			defaultSortOrder: "ascend",
			sorter: (a: any, b: any) => a.name.localeCompare(b.name),
			width: "100%",
			render: (text: any, project: ProjectInState) => (
				<TextEditor
					labelPlaceholder={project.name}
					editorPlaceholder="Name"
					originalText={project.name}
					onSave={(newName) => renameProject(project, newName)}
				/>
			),
		},
		{
			title: "Action",
			key: "action",
			render: (text: any, project: ProjectInState) => (
				<a
					href="/#"
					onClick={(e: React.MouseEvent) => promptToDelete(e, project)}
				>
					Delete
				</a>
			),
		},
	];

	return (
		<Table
			rowKey="id"
			dataSource={projectList}
			columns={columns}
			pagination={false}
			bordered={true}
		/>
	);
};
export default ProjectList;
