/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      isFavorite
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        isFavorite
        createdAt
        updatedAt
        version
        owner
      }
      nextToken
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      projectId
      parentTaskId
      isExpanded
      isCollapsed
      isFavorite
      isUrgent
      taskStatus
      typeId
      title
      description
      completionDate
      createdOn
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectId
        parentTaskId
        isExpanded
        isCollapsed
        isFavorite
        isUrgent
        taskStatus
        typeId
        title
        description
        completionDate
        createdOn
        createdAt
        updatedAt
        version
        owner
      }
      nextToken
    }
  }
`;
export const getSharedTheme = /* GraphQL */ `
  query GetSharedTheme($id: ID!) {
    getSharedTheme(id: $id) {
      id
      name
      isDefault
      backgrounds {
        nextToken
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const listSharedThemes = /* GraphQL */ `
  query ListSharedThemes(
    $filter: ModelSharedThemeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSharedThemes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        isDefault
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const getSharedBackground = /* GraphQL */ `
  query GetSharedBackground($id: ID!) {
    getSharedBackground(id: $id) {
      id
      url
      title
      theme {
        id
        name
        isDefault
        createdAt
        updatedAt
        version
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const listSharedBackgrounds = /* GraphQL */ `
  query ListSharedBackgrounds(
    $filter: ModelSharedBackgroundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSharedBackgrounds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        title
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
