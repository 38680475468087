import {
	ExportOutlined,
	ImportOutlined,
	PlusOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { DarkPagePanel, PageHeader } from "../../ui-util/StyledComponents";
import ProjectForm from "./ProjectForm";
import ProjectList from "./ProjectList";
import ImportDialog from "../header/options-menu/ImportDialog";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/reducers";
import { ProjectInState } from "../../store/reducers/projects";
import { ImportExportUtil } from "../../ui-util/ImportExportUtil";

const ProjectsPageSection = styled.section`
	flex: 1;
`;

const ProjectsPage = () => {
	const { allTasks, selectedProject } = useSelector((state: StoreState) => ({
		allTasks: state.tasks.allTasks,
		selectedProject: state.projects.projectList.find(
			(value) => value.id === state.projects.selectedProjectId
		) as ProjectInState,
	}));
	const [showImportDialog, setShowImportDialog] = useState(false);

	const exportProject = async () => {
		// Adapted from https://stackoverflow.com/a/55613750/132374
		const blob = ImportExportUtil.exportProject(allTasks);
		const href = await URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = href;
		link.download = selectedProject.name + ".json";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<DarkPagePanel>
			<ProjectsPageSection>
				<PageHeader>
					<h2>Projects</h2>
					<div>
						<Link to="/projects/create">
							<Button
								title="New project"
								shape="circle"
								icon={<PlusOutlined />}
							/>
						</Link>
						<Button
							title="Import into this project"
							shape="circle"
							icon={<ImportOutlined />}
							className="margin-left-small"
							disabled={!selectedProject}
							onClick={() => setShowImportDialog(true)}
						/>
						<Button
							title="Export this project as JSON"
							shape="circle"
							icon={<ExportOutlined />}
							className="margin-left-small"
							disabled={!selectedProject}
							onClick={exportProject}
						/>
					</div>
				</PageHeader>

				<Route exact path="/projects/" render={() => <ProjectList />} />
				<Route path="/projects/create" component={ProjectForm} />
			</ProjectsPageSection>

			{showImportDialog && (
				<ImportDialog
					projectId={selectedProject.id}
					closeDialog={() => setShowImportDialog(false)}
				/>
			)}
		</DarkPagePanel>
	);
};
export default ProjectsPage;
