import LeafCollapseToggle from "./LeafCollapseToggle";
import React from "react";
import { TaskInState } from "../../../store/reducers/tasks";
import { ColumnSpaceBetween } from "../../../ui-util/StyledComponents";
import CompletionIcon from "../common/CompletionIcon";
import styled from "styled-components";
import Settings from "../../../ui-util/Settings";

type LeafTopIconProps = {
	task: TaskInState;
	showCompletionIcon: boolean;
};
const LeafTopIcons = (props: LeafTopIconProps) => {
	return (
		<IconColumn className="margin-left-small">
			{props.showCompletionIcon && <CompletionIcon task={props.task} />}
			<LeafCollapseToggle task={props.task} />
			<div style={{ minHeight: Settings.mainIconSize }}></div>
		</IconColumn>
	);
};

const IconColumn = styled(ColumnSpaceBetween)`
	min-width: 20px;
	margin-top: 8px;
`;

export default LeafTopIcons;
