import { API, graphqlOperation } from "aws-amplify";
import { Dispatch } from "react";
import {
	CreateProjectInput,
	ListProjectsQuery,
	UpdateProjectInput,
	UpdateProjectMutation,
	DeleteTaskInput,
	DeleteProjectInput,
} from "../API";
import {
	createProject,
	updateProject,
	deleteTask,
	deleteProject,
} from "../graphql/mutations";
import { listProjects } from "../graphql/queries";
import {
	setProjects,
	updateProjectInStore,
	selectProject,
} from "../store/actions/projects";
import { ProjectInState } from "../store/reducers/projects";
import Notifications from "../ui-util/Notifications";
import { setTasksForProject } from "../store/actions/tasks";
import { message } from "antd";
import TaskApi from "./TaskApi";

export default class ProjectApi {
	static async loadProjects(
		dispatch: Dispatch<any>
	): Promise<ProjectInState[]> {
		try {
			const projects = (await API.graphql(graphqlOperation(listProjects))) as {
				data: ListProjectsQuery;
			};
			const projectsInState: ProjectInState[] = this.parseProjects(projects);
			projectsInState.sort((a, b) => {
				return a.name.localeCompare(b.name);
			});
			dispatch(setProjects(projectsInState));
			return projectsInState;
		} catch (e) {
			Notifications.handleError(
				`Sorry, the list of projects couldn't be loaded`,
				e
			);
		}
	}

	private static parseProjects(projects: {
		data: ListProjectsQuery;
	}): ProjectInState[] {
		const loadedProjects =
			(projects.data.listProjects && projects.data.listProjects.items) || [];
		const projectsInState: ProjectInState[] = [];
		loadedProjects.forEach((project) => {
			if (project) {
				const projectForState: ProjectInState = {
					id: project.id,
					name: project.name,
					version: project.version,
				};
				projectsInState.push(projectForState);
			}
		});
		return projectsInState;
	}

	static async createProject(project: CreateProjectInput) {
		try {
			await API.graphql(graphqlOperation(createProject, { input: project }));
		} catch (e) {
			Notifications.handleError(`Sorry, the project couldn't be created`, e);
		}
	}

	static async updateProject(
		project: UpdateProjectInput,
		dispatch: Dispatch<any>
	) {
		try {
			const result = (await API.graphql(
				graphqlOperation(updateProject, { input: project })
			)) as { data: UpdateProjectMutation };
			const updatedProject = result.data.updateProject;
			if (!updatedProject) {
				throw new Error("Unable to retrieve the updated project");
			}
			delete updatedProject.owner;
			dispatch(updateProjectInStore(updatedProject as ProjectInState));
		} catch (e) {
			Notifications.handleError(`Sorry, the project couldn't be updated`, e);
		}
	}

	static async deleteProject(
		project: ProjectInState,
		selectedProjectId: string,
		dispatch: Dispatch<any>
	) {
		try {
			// Deselect the project as needed
			const projectId = project.id as string;
			if (selectedProjectId === projectId) {
				dispatch(selectProject(null));
			}

			// Clear it's tasks from the store
			dispatch(setTasksForProject(projectId, []));

			// Delete its tasks
			const closeDeletionMessage = message.loading(
				"Removing the project's tasks...",
				0
			);
			try {
				const projectTasks = await TaskApi.loadTasks(projectId);
				for (const task of projectTasks) {
					const input: DeleteTaskInput = {
						id: task.id,
						expectedVersion: task.version as number,
					};
					await API.graphql(graphqlOperation(deleteTask, { input }));
				}
			} finally {
				closeDeletionMessage();
			}

			// Delete the project itself
			const closeProjectMessage = message.loading("Removing the project...", 0);
			try {
				const input: DeleteProjectInput = {
					id: projectId,
					expectedVersion: project.version as number,
				};
				await API.graphql(graphqlOperation(deleteProject, { input }));
			} finally {
				closeProjectMessage();
			}

			Notifications.handleSuccess("Project deleted");
		} catch (e) {
			Notifications.handleError(
				"Unable to delete the project.  Please try again later.",
				e
			);
		}
	}
}
