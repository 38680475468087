import { AnyAction, Reducer } from "redux";
import { CreateTaskInput } from "../../API";
import Tasks from "../../graphql-util/Tasks";
import { TaskType } from "../../graphql-util/TaskType";
import { TaskActionNames } from "../actions/tasks";

export type TaskInState = CreateTaskInput & {
	version?: number;
	unsavedTaskId?: string;
	editImmediately?: boolean;
	isHovered?: boolean;
};

export interface TaskState {
	isLoading: boolean;
	allTasks: TaskInState[];
	taskTypes: TaskType[];
	mostRecentlyHoveredLeafId: string | null;
}

const initialState: TaskState = {
	isLoading: false,
	allTasks: [],
	taskTypes: [],
	mostRecentlyHoveredLeafId: null,
};

export const tasks: Reducer<TaskState> = (
	state: TaskState = initialState,
	action: AnyAction
) => {
	switch (action.type) {
		case TaskActionNames.SetLoadingTasks:
			return {
				...state,
				isLoading: action.isLoading,
			};

		case TaskActionNames.SetTaskTypes:
			return {
				...state,
				taskTypes: action.taskTypes,
			};

		case TaskActionNames.SetTasksForProject:
			return {
				...state,
				allTasks: [
					...action.tasks.map((task: TaskInState) => Object.freeze(task)),
				],
			};

		case TaskActionNames.AppendTasksForProject:
			return {
				...state,
				allTasks: [
					...state.allTasks,
					...action.tasks.map((task: TaskInState) => Object.freeze(task)),
				],
			};

		case TaskActionNames.AddOrUpdateTask:
			const taskId = action.taskIdOverride || Tasks.getId(action.task);
			const existingTasks = state.allTasks.filter(
				(otherTask) => taskId !== Tasks.getId(otherTask)
			);
			return {
				...state,
				allTasks: [...existingTasks, Object.freeze(action.task)],
			};

		case TaskActionNames.SetMostRecentlyHoveredLeaf:
			return {
				...state,
				mostRecentlyHoveredLeafId: action.taskId,
			};

		case TaskActionNames.RemoveTask:
			const taskIdToRemove = Tasks.getId(action.task);
			return {
				...state,
				allTasks: state.allTasks.filter(
					(otherTask) => taskIdToRemove !== Tasks.getId(otherTask)
				),
			};

		default:
			return state;
	}
};
