import {
	LogoutOutlined,
	SettingOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logUserOut } from "../../store/reducers";
import UiUtil from "../../ui-util/UiUtil";
import { useHistory } from "react-router-dom";

type ProfileMenuProps = {
	className?: string;
};
const ProfileMenu = (props: ProfileMenuProps) => {
	const [userName, setUserName] = useState("");
	const [isAdmin, setIsAdmin] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		Auth.currentAuthenticatedUser().then((cognitoUser) => {
			setUserName(cognitoUser.attributes.given_name);
		});
		Auth.currentSession().then((session: any) => {
			setIsAdmin(UiUtil.isAdministrator(session));
		});
	}, []);

	const logout = () => {
		Auth.signOut();
		dispatch(logUserOut());
	};

	return (
		<Dropdown
			className={props.className}
			overlay={
				<Menu>
					<Menu.Item disabled={true}>
						<UserOutlined />
						{userName}
					</Menu.Item>
					{isAdmin && (
						<Menu.Item
							key="themesAdmin"
							icon={<SettingOutlined />}
							onClick={() => history.push("/admin/themes")}
						>
							Theme Admin
						</Menu.Item>
					)}
					<Menu.Item key="logout" icon={<LogoutOutlined />} onClick={logout}>
						Log out
					</Menu.Item>
				</Menu>
			}
			trigger={["click"]}
		>
			<UserOutlined style={{ fontSize: "1.75em" }} />
		</Dropdown>
	);
};
export default ProfileMenu;
