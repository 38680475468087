import React, { CSSProperties, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Tasks from "../../graphql-util/Tasks";
import { setMostRecentlyHoveredLeaf } from "../../store/actions/tasks";
import { StoreState } from "../../store/reducers";
import { StyledCompProps } from "../../ui-util/StyledComponents";
import AddIcon from "./AddIcon";
import LeafAndChildren from "./LeafAndChildren";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { setIsDragging } from "../../store/actions/settings";
import TaskApi from "../../graphql-util/TaskApi";
import { useParams } from "react-router-dom";
import UrlUtil from "../../ui-util/UrlUtil";

type TreeProps = StyledCompProps;
const BranchesAndLeaves = (props: TreeProps) => {
	const dispatch = useDispatch();
	const { selectedProjectId, topLevelTasks, allTasks } = useSelector(
		(state: StoreState) => {
			const pastTasksAsOfDate = state.settings.pastTasksAsOfDate;
			return {
				selectedProjectId: state.projects.selectedProjectId,
				topLevelTasks: Tasks.getDirectChildTasks(
					null,
					state,
					false,
					pastTasksAsOfDate
				),
				allTasks: state.tasks.allTasks,
			};
		}
	);

	const deselectLeaves = () => {
		dispatch(setMostRecentlyHoveredLeaf(null));
	};

	const onDragStart = () => {
		dispatch(setIsDragging(true));
	};

	const onDragEnd = (dropResult: DropResult) => {
		dispatch(setIsDragging(false));
		if (dropResult.destination) {
			const taskId = dropResult.draggableId;
			const task = Tasks.getTask(taskId, allTasks);
			const newParentId = dropResult.destination.droppableId;
			TaskApi.updateTask(task, dispatch, {
				parentTaskId: newParentId,
			});
		}
	};

	const isProjectSelected = !!selectedProjectId;
	return (
		<div
			id="tree"
			className={`${props.className} margin-top-medium`}
			onClick={deselectLeaves}
		>
			{isProjectSelected && (
				<>
					<DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
						{topLevelTasks.map((task) => (
							<LeafAndChildren key={Tasks.getKey(task)} task={task} />
						))}
						<AddIcon parentTaskId={null} />
					</DragDropContext>
				</>
			)}
		</div>
	);
};
const StyledBranchesAndLeaves = styled(BranchesAndLeaves)`
	/* 175px of trunk is visible */
	margin-left: 185px;
`;

const Tree = () => {
	const dispatch = useDispatch();
	const { projectName } = useParams<{ projectName?: string }>();
	const { projectId } = useSelector((state: StoreState) => {
		const project = state.projects.projectList.find(
			(p) => p.name === projectName
		);
		return {
			projectId: project?.id,
		};
	});
	useEffect(() => UrlUtil.loadProjectFromRoute(projectId, dispatch), [
		projectId,
		dispatch,
	]);

	const treeStyle: CSSProperties = {
		background: 'url("/images/tree/trunk.jpg") 100% repeat-y',
		backgroundPosition: "left top",
		minHeight: "100vh",
	};
	return (
		<div
			className="padding-top-medium padding-bottom-medium padding-right-medium"
			style={treeStyle}
		>
			<StyledBranchesAndLeaves />
		</div>
	);
};
export default Tree;
