import React, { CSSProperties } from "react";
import { DownOutlined, RightOutlined } from "@ant-design/icons/lib";
import { TaskInState } from "../../../store/reducers/tasks";
import TaskApi from "../../../graphql-util/TaskApi";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../store/reducers";

type LeafCollapseToggleProps = {
	task: TaskInState;
	className?: string;
};

const LeafCollapseToggle = (props: LeafCollapseToggleProps) => {
	const dispatch = useDispatch();
	const { peekCollapsedTasks } = useSelector((state: StoreState) => {
		return {
			peekCollapsedTasks: state.settings.peekCollapsedTasks,
		};
	});

	const isCollapsed = props.task.isCollapsed && !peekCollapsedTasks;

	const toggleCollapsed = () => {
		TaskApi.updateTask(props.task, dispatch, {
			isCollapsed: !isCollapsed,
		});
	};

	const style: CSSProperties = {
		marginRight: "3px",
		opacity: props.task.isHovered ? 0.3 : 0.15,
	};

	const collapseIcon = peekCollapsedTasks ? (
		<RightOutlined title="Collapsed" />
	) : (
		<RightOutlined title="Collapsed" onClick={toggleCollapsed} />
	);
	const expandIcon = peekCollapsedTasks ? (
		<DownOutlined title="Expanded" />
	) : (
		<DownOutlined title="Expanded" onClick={toggleCollapsed} />
	);

	return (
		<div className={props.className} style={style}>
			{isCollapsed ? collapseIcon : expandIcon}
		</div>
	);
};
export default LeafCollapseToggle;
