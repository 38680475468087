import React from "react";
import { fadeIn, merge, rotateInUpLeft } from "react-animations";
import { useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import Tasks from "../../graphql-util/Tasks";
import { StoreState } from "../../store/reducers";
import { TaskInState } from "../../store/reducers/tasks";
import Settings from "../../ui-util/Settings";
import AddIcon from "./AddIcon";
import Leaf from "./leaves/Leaf";

type LeafAndChildrenProps = {
	task: TaskInState;
};

const LeafAndChildren = (props: LeafAndChildrenProps) => {
	const { mostRecentlyHoveredLeafId, childTasks, allTasks } = useSelector(
		(state: StoreState) => {
			const pastTasksAsOfDate = state.settings.pastTasksAsOfDate;
			return {
				mostRecentlyHoveredLeafId: state.tasks.mostRecentlyHoveredLeafId,
				childTasks: Tasks.getDirectChildTasks(
					props.task,
					state,
					false,
					pastTasksAsOfDate
				),
				allTasks: state.tasks.allTasks,
			};
		}
	);

	const task = props.task;

	const isSaved = !!task.id;
	const isHoveredOrMostRecent =
		props.task.isHovered ||
		mostRecentlyHoveredLeafId === Tasks.getId(props.task) ||
		!isSaved;
	const isTaskOrParentCollapsed = Tasks.isTaskOrParentCollapsed(task, allTasks);

	return (
		<StyledLeafAndChildren
			style={{ marginRight: childTasks.length ? 0 : "15px" }}
		>
			<Leaf task={props.task} />

			<StyledLeafChildren>
				{!isTaskOrParentCollapsed &&
					childTasks.map((child) => (
						<LeafAndChildren key={Tasks.getKey(child)} task={child} />
					))}
				{isHoveredOrMostRecent && isSaved && (
					<AddIcon parentTaskId={props.task.id as string} />
				)}
			</StyledLeafChildren>
		</StyledLeafAndChildren>
	);
};

const leafAppearAnimation = keyframes`${merge(rotateInUpLeft, fadeIn)}`;

const StyledLeafAndChildren = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
	animation: ${Settings.leafAnimationTime} ${leafAppearAnimation};
`;

const StyledLeafChildren = styled.div`
	display: flex;
	flex-direction: column;
`;

export default LeafAndChildren;
