import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { FormEvent } from "react";

export default class FormUtil {
	static isFormInvalid(props: FormComponentProps): boolean {
		const fieldsError: any = props.form.getFieldsError();
		return Object.keys(fieldsError).some((field) => fieldsError[field]);
	}

	static getError(field: string, props: FormComponentProps) {
		const form = props.form;
		return form.isFieldsTouched([field]) && form.getFieldError(field);
	}

	static handleSubmit(
		props: FormComponentProps,
		e: FormEvent<any>,
		saveCallback: (values: any) => void
	) {
		e.preventDefault();

		props.form.validateFields(async (err, values) => {
			if (!err) {
				saveCallback(values);
			}
		});
	}
}
