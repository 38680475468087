import Amplify, { Auth, Hub } from "aws-amplify";
import awsconfig from "./aws-exports";
import Home from "./ui/Home";
import React, { useEffect, useState } from "react";
import Homepage from "./homepage/Homepage";

Amplify.configure(awsconfig);

const App = () => {
	const [isLoggedIn, setLoggedIn] = useState(false);
	useEffect(() => {
		// If the user is already logged in, navigate onwards
		Auth.currentAuthenticatedUser()
			.then(() => {
				setLoggedIn(true);
			})
			.catch(() => {
				// The user isn't authenticated; do nothing
			});

		Hub.listen("auth", (data) => {
			const { payload } = data;
			switch (payload.event) {
				case "signIn":
					setLoggedIn(true);
					break;

				case "signOut":
					setLoggedIn(false);
					break;
			}
		});
	}, []);

	return (
		<>
			{!isLoggedIn && <Homepage />}
			{isLoggedIn && <Home />}
		</>
	);
};
export default App;
