import { useState, useEffect } from "react";
import ThemeApi, { SharedTheme } from "../../../graphql-util/ThemeApi";
import { ColumnProps } from "antd/lib/table";
import { Checkbox, Table, Modal } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { ClickableRow } from "../../../ui-util/StyledComponents";

const ThemeAdminList = () => {
	const [sharedThemes, setSharedThemes] = useState([] as SharedTheme[]);
	const history = useHistory();

	const loadThemes = async () => {
		const themes = await ThemeApi.loadSharedThemes();
		setSharedThemes(themes);
	};

	useEffect(() => {
		loadThemes();
	}, []);

	const editTheme = (theme: SharedTheme) => {
		history.push(`/admin/themes/edit/${theme.id}`);
	};

	const promptToDelete = (e: React.MouseEvent, theme: SharedTheme) => {
		e.stopPropagation();
		e.preventDefault();
		Modal.confirm({
			title: "Are you sure?",
			content:
				"Do you want to PERMANENTLY remove this theme and all of its backgrounds?",
			okType: "danger",
			async onOk() {
				await ThemeApi.deleteSharedTheme(theme);
				loadThemes();
			},
		});
	};

	const columns: ColumnProps<any>[] = [
		{
			title: "Name",
			key: "name",
			defaultSortOrder: "ascend",
			sorter: (a: any, b: any) => a.name.localeCompare(b.name),
			width: "100%",
			render: (text: any, theme: SharedTheme) => (
				<ClickableRow>{theme.name}</ClickableRow>
			),
		},
		{
			title: "Default?",
			key: "default",
			render: (text: any, theme: SharedTheme) => (
				<Checkbox defaultChecked={theme.isDefault} disabled />
			),
		},
		{
			title: "Action",
			key: "action",
			render: (text: any, theme: SharedTheme) => (
				<a
					href="/#"
					onClick={(e: React.MouseEvent) => promptToDelete(e, theme)}
				>
					Delete
				</a>
			),
		},
	];

	return (
		<Table
			rowKey="id"
			dataSource={sharedThemes}
			columns={columns}
			onRow={(theme: SharedTheme) => ({
				onClick: () => editTheme(theme),
			})}
			pagination={false}
			bordered={true}
		/>
	);
};
export default ThemeAdminList;
