import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Tasks from "../../../graphql-util/Tasks";
import { addOrUpdateTask } from "../../../store/actions/tasks";
import { StoreState } from "../../../store/reducers";

type AddSubTaskLinkProps = {
	parentTaskId: string | null;
};
const AddSubTaskLink = (props: AddSubTaskLinkProps) => {
	const dispatch = useDispatch();
	const { projectId } = useSelector((state: StoreState) => ({
		projectId: state.projects.selectedProjectId as string,
	}));

	const createSubTask = () => {
		const task = Tasks.createDummyTask(projectId, props.parentTaskId, false);
		dispatch(addOrUpdateTask(task));
	};

	return (
		<Button type="link" icon={<PlusOutlined />} onClick={() => createSubTask()}>
			Add sub-task
		</Button>
	);
};
export default AddSubTaskLink;
