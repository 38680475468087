import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Tasks from "../../../graphql-util/Tasks";
import { setMostRecentlyHoveredLeaf } from "../../../store/actions/tasks";
import { StoreState } from "../../../store/reducers";
import { TaskInState } from "../../../store/reducers/tasks";
import Settings from "../../../ui-util/Settings";
import { FlexColumn, FlexRow } from "../../../ui-util/StyledComponents";
import UiUtil from "../../../ui-util/UiUtil";
import AddSubTaskLink from "./AddSubTaskLink";
import TitleOrDescription from "../common/TitleOrDescription";
import LeafCollapsedEllipsis from "./LeafCollapsedEllipsis";
import LeafActionIcons from "./LeafActionIcons";
import { TaskStatus } from "../../../API";
import { SubTasks } from "../subtasks/SubTasks";
import LeafTopIcons from "./LeafTopIcons";
import { Droppable } from "react-beautiful-dnd";

type LeafProps = {
	task: TaskInState;
};

const Leaf = (props: LeafProps) => {
	const dispatch = useDispatch();
	const task = props.task;
	const {
		mostRecentlyHoveredLeafId,
		subTasks,
		peekCollapsedTasks,
		isDragging,
	} = useSelector((state: StoreState) => {
		const pastTasksAsOfDate = state.settings.pastTasksAsOfDate;
		return {
			mostRecentlyHoveredLeafId: state.tasks.mostRecentlyHoveredLeafId,
			subTasks: Tasks.getDirectChildTasks(task, state, true, pastTasksAsOfDate),
			peekCollapsedTasks: state.settings.peekCollapsedTasks,
			isDragging: state.settings.isDragging,
		};
	});
	const hasIncompleteSubTasks = subTasks.some(
		(subTask) => !Tasks.isComplete(subTask)
	);
	const isCollapsed = task.isCollapsed && !peekCollapsedTasks;
	const showCompleteIndicator =
		Tasks.isComplete(task) && !hasIncompleteSubTasks;
	const isTitlePresent = task.title && !!task.title.trim();
	const isDescriptionPresent = task.description && !!task.description.trim();

	const updateHoverState = (isHovered: boolean) => {
		Tasks.updateHoverState(isHovered, task, dispatch);
		if (isHovered) {
			dispatch(setMostRecentlyHoveredLeaf(Tasks.getId(task)));
		}
	};

	const determineOpacity = () => {
		if (showCompleteIndicator) {
			return Settings.completeTaskOpacity;
		} else if (Tasks.hasStatus(task, TaskStatus.ON_HOLD)) {
			return Settings.onHoldTaskOpacity;
		}
		return 1;
	};

	const determineShadow = (isDraggingOver: boolean) => {
		let topShadow = createShadow("white", true);
		let bottomShadow = createShadow("rgba(0,0,0,.4)");
		if (task.isHovered && !Tasks.hasStatus(task, TaskStatus.ON_HOLD)) {
			topShadow = createShadow("white", true, 5);
			bottomShadow = createShadow("white");
		}
		if (isDraggingOver) {
			bottomShadow = createShadow("rgba(255,255,255,.8)", false);
		} else if (task.isUrgent) {
			bottomShadow = createShadow("rgba(255,0,0,.8)", false, 6, 6);
		} else if (task.isFavorite) {
			bottomShadow = createShadow("rgba(255,255,0,.8)", false, 7, 7);
		}
		return `${topShadow}, ${bottomShadow}`;
	};

	const createShadow = (
		color: string,
		isInset = false,
		length = 2,
		blur = 5,
		spread = 0
	) => {
		return `${
			isInset ? "inset " : ""
		} ${length}px ${length}px ${blur}px ${spread}px ${color}`;
	};

	const isSaved = !!task.id;
	const isHoveredOrMostRecent =
		(task.isHovered ||
			mostRecentlyHoveredLeafId === Tasks.getId(task) ||
			!isSaved) &&
		!isDragging;

	return (
		<Droppable droppableId={Tasks.getKey(task)}>
			{(droppableProvided, snapshot) => (
				<StyledLeaf
					style={{
						opacity: determineOpacity(),
						minWidth: !isSaved ? "75px" : "225px",
						boxShadow: determineShadow(snapshot.isDraggingOver),
					}}
					onMouseEnter={() => updateHoverState(true)}
					onMouseLeave={() => updateHoverState(false)}
					onClick={(e) => UiUtil.preventClickPropagation(e)}
					ref={droppableProvided.innerRef}
				>
					<FlexRow className="overflow-auto" style={{ alignItems: "unset" }}>
						<LeafTopIcons task={task} showCompletionIcon={isSaved} />

						<FlexColumn className="margin-left-small flex-grow">
							{(isHoveredOrMostRecent || isTitlePresent) && (
								<TitleOrDescription
									isTitle={true}
									editorTextStyle={{ fontWeight: "bold" }}
									task={task}
								/>
							)}

							{isCollapsed && <LeafCollapsedEllipsis task={task} />}

							{isDescriptionPresent && !isCollapsed && (
								<TitleOrDescription isTitle={false} task={task} />
							)}
							{!isDescriptionPresent &&
								!isCollapsed &&
								isHoveredOrMostRecent && (
									<TitleOrDescription isTitle={false} task={task} />
								)}
						</FlexColumn>
					</FlexRow>

					{/*	Sub-tasks */}
					{!isCollapsed && (
						<SubTasks task={task} droppableProvided={droppableProvided} />
					)}
					{isHoveredOrMostRecent && !isCollapsed && isSaved && task.id && (
						<AddSubTaskLink parentTaskId={task.id} />
					)}

					{isHoveredOrMostRecent && (
						<LeafActionIcons
							task={task}
							isSaved={isSaved}
							className="margin-top-medium"
							style={{ flex: 1, alignItems: "flex-end" }}
						/>
					)}

					{/* Horizontal branch */}
					<Branch
						src="/images/tree/horizontal_branch.jpg"
						alt="Branch"
						className={
							task.parentTaskId ? "horizontal-branch" : "branch-to-trunk"
						}
					/>
				</StyledLeaf>
			)}
		</Droppable>
	);
};

const StyledLeaf = styled(FlexColumn)`
	// Shape
	max-width: 400px;
	min-height: 80px;

	position: relative;
	word-wrap: break-word;
	padding: 15px;
	margin-left: 20px;

	background: url(/images/leaf_backgrounds/leaf-congruent_pentagon.png) repeat;
	background-size: 50%;

	border-top-left-radius: 40px;
	border-bottom-right-radius: 42px;

	box-shadow: 2px 2px 5px 0 rgba(100, 100, 100, 1);

	transition: ${Settings.quickAnimationTime};
`;

const Branch = styled.img`
	position: absolute;
	bottom: 10px;
	height: 50%;
	max-height: 200px;
`;

export default Leaf;
