import { Button } from "antd";
import React from "react";
import { zoomInRight } from "react-animations";
import { useDispatch, useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import Tasks from "../../graphql-util/Tasks";
import { addOrUpdateTask } from "../../store/actions/tasks";
import { StoreState } from "../../store/reducers";
import { PlusOutlined } from "@ant-design/icons";

type AddIconProps = {
	parentTaskId: string | null;
};
const AddIcon = (props: AddIconProps) => {
	const dispatch = useDispatch();
	const { projectId } = useSelector((state: StoreState) => ({
		projectId: state.projects.selectedProjectId as string,
	}));

	const createEmptyTask = () => {
		const dummyTask = Tasks.createDummyTask(
			projectId,
			props.parentTaskId,
			true
		);
		dispatch(addOrUpdateTask(dummyTask));
	};

	return (
		<StyledButton
			type="primary"
			shape="circle"
			icon={<PlusOutlined />}
			className="margin-left-medium margin-bottom-medium"
			style={{ opacity: 0.7 }}
			onClick={createEmptyTask}
		/>
	);
};

const StyledButton = styled(Button)`
	animation: 0.3s ${keyframes`${zoomInRight}`};
`;

export default AddIcon;
