import TextArea from "antd/lib/input/TextArea";
import React, { CSSProperties, KeyboardEvent, useState } from "react";
import ReactMarkdown from "react-markdown";
import Settings from "./Settings";
import { RowAlignedCenter } from "./StyledComponents";
import { InfoIcon } from "./InfoIcon";

type TextEditorProps = {
	labelPlaceholder: string;
	editorPlaceholder: string;
	editorTextStyle?: CSSProperties;
	editImmediately?: boolean;
	originalText: string;
	onSave: (text: string) => void;
};
const TextEditor = (props: TextEditorProps) => {
	const [isEditing, setEditing] = useState(!!props.editImmediately);
	const [persistedText, setPersistedText] = useState(props.originalText);
	const [text, setText] = useState(props.originalText);

	const saveChanges = () => {
		if (text !== persistedText) {
			setPersistedText(text);
			props.onSave(text);
		}
		setEditing(false);
	};
	const cancelEditing = () => {
		setText(persistedText);
		setEditing(false);
	};
	const onKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
		if (
			(event.ctrlKey || event.metaKey) &&
			(event.keyCode === 13 || event.keyCode === 10)
		) {
			saveChanges();
		} else if (event.keyCode === 27) {
			cancelEditing();
		}
	};

	const editorTextStyle: CSSProperties = {
		cursor: "pointer",
		overflowY: "auto",
		maxHeight: Settings.editorMaxHeight,
		...(props.editorTextStyle || {}),
	};
	const isPersistedTextPresent = persistedText && !!persistedText.trim();
	return (
		<>
			{/* Placeholder text */}
			{!isEditing && !isPersistedTextPresent && (
				<div onClick={() => setEditing(true)}>
					<ReactMarkdown className="text-editor-display">
						{props.labelPlaceholder}
					</ReactMarkdown>
				</div>
			)}

			{/* Complete text, displayed as markdown */}
			{!isEditing && isPersistedTextPresent && (
				<div style={editorTextStyle} onClick={() => setEditing(true)}>
					<ReactMarkdown className="text-editor-display">
						{persistedText}
					</ReactMarkdown>
				</div>
			)}

			{/* Text editor */}
			{isEditing && (
				<form>
					<RowAlignedCenter>
						<TextArea
							placeholder={props.editorPlaceholder}
							className="text-editor"
							autoSize={{ minRows: 1, maxRows: 15 }}
							cols={35}
							value={text}
							autoFocus={true}
							onKeyDown={onKeyDown}
							onChange={(e) => setText(e.target.value)}
							onBlur={saveChanges}
						/>
						<InfoIcon
							title="Hit CTRL-Enter or Command-Enter to save (or click outside of the textbox).  ESC to cancel."
							className="margin-left-small"
						/>
					</RowAlignedCenter>
				</form>
			)}
		</>
	);
};
export default TextEditor;
