import { Dispatch } from "redux";
import { selectProject } from "../store/actions/projects";
import TaskApi from "../graphql-util/TaskApi";
import ProjectApi from "../graphql-util/ProjectApi";

export default class UrlUtil {
	/** The homepage is a special case because it may be either '/' or '' */
	static isHomepage(locationPathName: string) {
		return (
			locationPathName === "/" ||
			!locationPathName ||
			this.isViewingProject(locationPathName)
		);
	}

	static isViewingProject(locationPathName: string) {
		return (
			locationPathName.includes("/projects/") &&
			!locationPathName.endsWith("/projects/") &&
			!locationPathName.endsWith("/projects/create")
		);
	}

	static loadBasicData(dispatch: Dispatch) {
		ProjectApi.loadProjects(dispatch);
		TaskApi.loadTaskTypes(dispatch);
	}

	static loadProjectFromRoute(projectId: string, dispatch: Dispatch) {
		if (projectId) {
			dispatch(selectProject(projectId));
			TaskApi.loadTasksForProject(projectId, dispatch);
		}
	}

	static getLinkToProject(projectName?: string): string {
		return projectName ? `/projects/${encodeURI(projectName)}` : "/";
	}
}
