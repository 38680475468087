import React from "react";
import { Link } from "react-router-dom";
import { StoreState } from "../store/reducers";
import { useSelector } from "react-redux";
import { ArrowLeftOutlined } from "@ant-design/icons";
import UrlUtil from "../ui-util/UrlUtil";

type LinkToProjectProps = (
	| { projectName: string }
	| { projectId: string }
	| {}
) & {
	isBackLink?: boolean;
	className?: string;
};
const LinkToProject = (props: LinkToProjectProps) => {
	const projectName = useSelector((state: StoreState) => {
		if ("projectName" in props) {
			return props.projectName;
		} else if ("projectId" in props) {
			const project = state.projects.projectList.find(
				(p) => p.id === props.projectId
			);
			return project?.name;
		} else {
			return "Home";
		}
	});
	return (
		<Link
			to={UrlUtil.getLinkToProject(projectName)}
			className={props.className}
		>
			{props.isBackLink && (
				<ArrowLeftOutlined className="margin-right-medium" />
			)}
			{projectName}
		</Link>
	);
};

export default LinkToProject;
