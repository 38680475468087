import { AnyAction, Reducer } from "redux";
import { UndoAction } from "../../ui-util/UndoActions";
import { SettingActionNames } from "../actions/settings";

export type SettingsState = {
	peekCollapsedTasks: boolean;
	showPastTasksSlider: boolean;
	pastTasksAsOfDate: Date | null;
	isDragging: boolean;
	undoActions: UndoAction[];
};

const initialState: SettingsState = {
	peekCollapsedTasks: false,
	showPastTasksSlider: false,
	pastTasksAsOfDate: null,
	isDragging: false,
	undoActions: [],
};

export const settings: Reducer<SettingsState> = (
	state: SettingsState = initialState,
	action: AnyAction
) => {
	switch (action.type) {
		case SettingActionNames.SetPeekCollapsedTasks:
			return {
				...state,
				peekCollapsedTasks: action.peekCollapsedTasks,
			};

		case SettingActionNames.ShowPastTasksSlider:
			return {
				...state,
				showPastTasksSlider: action.showPastTasksSlider,
			};

		case SettingActionNames.SetPastTaskAsOfDate:
			return {
				...state,
				pastTasksAsOfDate: action.date,
			};

		case SettingActionNames.SetIsDragging:
			return {
				...state,
				isDragging: action.dragging,
			};

		case SettingActionNames.AddUndoAction:
			return {
				...state,
				undoActions: [...state.undoActions, action.undoAction],
			};

		case SettingActionNames.ClearUndoActions:
			return {
				...state,
				undoActions: [],
			};

		default:
			return state;
	}
};
