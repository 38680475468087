import { Layout } from "antd";
import React, { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import { StoreState } from "../../store/reducers";
import {
	RowAlignedCenter,
	RowJustifiedBaseline,
} from "../../ui-util/StyledComponents";
import ProfileMenu from "./ProfileMenu";
import ProjectSelector from "./ProjectSelector";
import Toolbar from "./Toolbar";
import UrlUtil from "../../ui-util/UrlUtil";
import LinkToProject from "../LinkToProject";
import UiUtil from "../../ui-util/UiUtil";
import { SyncOutlined } from "@ant-design/icons";
import Settings from "../../ui-util/Settings";
import TaskApi from "../../graphql-util/TaskApi";

const HeaderContainer = styled(Layout.Header)`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const ProjectLink = withRouter((props: RouteComponentProps) => {
	const {hasProjects, isProjectSelected, selectedProjectId} = useSelector(
		(state: StoreState) => {
			const projectId = state.projects.selectedProjectId;
			return {
				hasProjects: state.projects.projectList.length > 0,
				isProjectSelected: !!projectId,
				selectedProjectId: projectId,
			};
		}
	);
	const isOnHomepage = UrlUtil.isHomepage(props.location.pathname);

	function createLink(): ReactNode {
		if (isOnHomepage && !hasProjects) {
			return <Link to="/projects/create">Create a project</Link>;
		} else if (isOnHomepage) {
			return <ProjectSelector/>;
		} else if (isProjectSelected) {
			return <LinkToProject projectId={selectedProjectId} isBackLink={true}/>;
		} else {
			return <LinkToProject isBackLink={true}/>;
		}
	}

	return <div className="project-title">{createLink()}</div>;
});

const Header = () => {
	const dispatch = useDispatch();
	const {projectId, isLoadingTasks} = useSelector((state: StoreState) => ({
		projectId: state.projects.selectedProjectId,
		isLoadingTasks: state.tasks.isLoading,
	}));
	const {isProjectSelected} = useSelector((state: StoreState) => ({
		isProjectSelected: !!state.projects.selectedProjectId,
	}));

	const refresh = () => {
		TaskApi.loadTasksForProject(projectId, dispatch);
	};

	return (
		<HeaderContainer>
			<RowJustifiedBaseline>
				<ProjectLink/>
				{isProjectSelected &&
				UiUtil.createIcon({
					...Settings.headerIconConfig,
					tooltip: "Refresh project",
					icon: <SyncOutlined/>,
					leftSpacing: true,
					isDisabled: isLoadingTasks,
					onClick: refresh,
				})}
			</RowJustifiedBaseline>

			{isProjectSelected && (
				<Toolbar className="margin-left-medium margin-right-medium"/>
			)}

			<RowAlignedCenter style={{color: "white"}}>
				<ProfileMenu/>
			</RowAlignedCenter>
		</HeaderContainer>
	);
};
export default Header;
