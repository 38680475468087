import React, { CSSProperties } from "react";
import { TaskInState } from "../../../store/reducers/tasks";
import UiUtil from "../../../ui-util/UiUtil";
import {
	ArrowLeftOutlined,
	DeleteOutlined,
	ExclamationOutlined,
	PauseOutlined,
	StarOutlined,
} from "@ant-design/icons/lib";
import { RowSpaceBetween } from "../../../ui-util/StyledComponents";
import TaskApi from "../../../graphql-util/TaskApi";
import { useDispatch, useSelector } from "react-redux";
import { TaskStatus } from "../../../API";
import Tasks from "../../../graphql-util/Tasks";
import Notifications from "../../../ui-util/Notifications";
import { StoreState } from "../../../store/reducers";
import { removeTask } from "../../../store/actions/tasks";

type LeafActionIconsProps = {
	task: TaskInState;
	isSaved: boolean;
	className?: string;
	style?: CSSProperties;
};
const LeafActionIcons = (props: LeafActionIconsProps) => {
	const dispatch = useDispatch();
	const {allTasks} = useSelector((state: StoreState) => ({
		allTasks: state.tasks.allTasks,
	}));

	const task = props.task;
	const canPutOnHold = !Tasks.isComplete(props.task);
	const hasChildren = Tasks.doesTaskHaveAnyChildren(task.id, allTasks);
	const hasVisibleChildren = Tasks.doesTaskHaveVisibleChildren(task.id, allTasks);

	const canBeCollapsed =
		task.isExpanded &&
		!(task.title && task.description) &&
		!hasChildren;

	const toggleFavorite = () => {
		TaskApi.updateTask(task, dispatch, {
			isFavorite: !task.isFavorite,
		});
	};
	const toggleUrgent = () => {
		TaskApi.updateTask(task, dispatch, {
			isUrgent: !task.isUrgent,
		});
	};
	const toggleOnHold = () => {
		TaskApi.updateTask(task, dispatch, {
			taskStatus:
				task.taskStatus === TaskStatus.ON_HOLD ? null : TaskStatus.ON_HOLD,
		});
	};

	const collapseTask = () => {
		// Sub-tasks can only be not started or completed (not on hold, etc...)
		TaskApi.updateTask(task, dispatch, {
			isExpanded: false,
			taskStatus: null,
		});
	};
	const deleteTask = async () => {
		if (props.isSaved) {
			const children = Tasks.getAllChildren(props.task.id as string, allTasks);
			children.forEach((child) => TaskApi.deleteTask(child, dispatch));
			await TaskApi.deleteTask(task, dispatch);
			Notifications.handleSuccess(`${hasVisibleChildren ? 'Tasks' : 'Task'} deleted`);
		} else {
			dispatch(removeTask(task));
		}
	};

	return (
		<RowSpaceBetween className={props.className} style={props.style}>
			<div>
				{props.isSaved &&
				canBeCollapsed &&
				UiUtil.createIcon({
					tooltip: "Collapse",
					icon: <ArrowLeftOutlined/>,
					onClick: collapseTask,
				})}
			</div>

			<div>
				{props.isSaved &&
				UiUtil.createIcon({
					tooltip: "Favorite",
					icon: <StarOutlined/>,
					isSelected: props.task.isFavorite,
					onClick: toggleFavorite,
				})}
				{props.isSaved &&
				UiUtil.createIcon({
					tooltip: "Urgent",
					icon: <ExclamationOutlined/>,
					isSelected: props.task.isUrgent,
					onClick: toggleUrgent,
				})}
				{props.isSaved &&
				canPutOnHold &&
				UiUtil.createIcon({
					tooltip: "Oh hold",
					icon: <PauseOutlined/>,
					isSelected: props.task.taskStatus === TaskStatus.ON_HOLD,
					onClick: toggleOnHold,
				})}
			</div>

			<div>
				{props.isSaved &&
				UiUtil.createIcon({
					tooltip: "Delete",
					icon: <DeleteOutlined/>,
					requireConfirmation: hasVisibleChildren,
					confirmationMessage:
						"Are you sure?  Sub-tasks will also be deleted.",
					onClick: deleteTask,
				})}
				{!props.isSaved &&
				UiUtil.createIcon({
					tooltip: "Delete this draft",
					icon: <DeleteOutlined/>,
					onClick: deleteTask,
				})}
			</div>
		</RowSpaceBetween>
	);
};
export default LeafActionIcons;
