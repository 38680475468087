import { AnyAction, combineReducers, Dispatch } from "redux";
import { projects, ProjectState } from "./projects";
import { settings, SettingsState } from "./settings";
import { tasks, TaskState } from "./tasks";

export type StoreState = {
	projects: ProjectState;
	tasks: TaskState;
	settings: SettingsState;
};

const appReducer = combineReducers({
	projects,
	tasks,
	settings,
});

export enum RootActionNames {
	LogUserOut = "LogUserOut",
}

export const logUserOut = () => ({
	type: RootActionNames.LogUserOut,
});
export type RootActionProps = {
	logUserOut: () => any;
};
export const mapDispatchToPropsForRoot = (dispatch: Dispatch) => ({
	logUserOut: () => dispatch(logUserOut()),
});

// See https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
const rootReducer = (state: any, action: AnyAction) => {
	if (action.type === RootActionNames.LogUserOut) {
		state = undefined;
	}

	return appReducer(state, action);
};
export default rootReducer;
