import React from "react";
import styled, { keyframes } from "styled-components";
import { zoomInLeft } from "react-animations";
import Settings from "./Settings";

export interface StyledCompProps {
	// see https://github.com/Microsoft/TypeScript/issues/8588
	children?: React.ReactChild;
	className?: string;
}

export const Panel = styled.div`
	margin: 50px;
	border-radius: 10px;
	padding: 25px;
	min-height: 400px;
`;

export const DarkPagePanel = styled(Panel)`
	color: white;
	border: 1px solid #444444;
	background-image: url("/images/dark-wood.jpg");
	// In case the panel appears before the image has loaded
	background-color: #6a4233;
	background-size: cover;
	box-shadow: 4px 4px 12px rgba(0, 0, 0, 1),
		inset 0 2px 2px rgba(255, 255, 255, 0.56);

	& h2,
	& h4 {
		color: white;
	}
`;

export const PageHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const FlexRow = styled.div`
	display: flex;
	align-items: start;
`;

export const FlexRowWrapped = styled(FlexRow)`
	flex-wrap: wrap;
`;

export const RowAlignedCenter = styled.div`
	display: flex;
	align-items: center;
`;

export const RowJustifiedCenter = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const RowJustifiedBaseline = styled.div`
	display: flex;
	justify-content: center;
	align-items: baseline;
`;

export const RowJustifiedRight = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

export const RowSpaceBetween = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const RowSpaceAround = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
`;

export const RowAlignedBaseline = styled.div`
	display: flex;
	align-items: baseline;
`;

export const ClickableRow = styled.div`
	cursor: pointer;
`;

export const FlexColumn = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ColumnSpaceBetween = styled(FlexColumn)`
	justify-content: space-between;
`;

export const ColumnAlignedCenter = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const animateIn = keyframes`${zoomInLeft}`;
export const AnimateIn = styled.div`
	animation: ${Settings.quickAnimationTime} ${animateIn};
`;
