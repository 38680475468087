import { Slider } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { setPastTaskAsOfDate } from "../../store/actions/settings";
import { RowAlignedCenter } from "../../ui-util/StyledComponents";

type PastTasksSliderProps = {
	className?: string;
};
const PastTasksSlider = (props: PastTasksSliderProps) => {
	const dispatch = useDispatch();
	const [sliderLabel, setSliderLabel] = useState("None");

	const getDateInPast = (daysAgo: number) => {
		const now = new Date();
		const dayToShowAllTasksFrom = new Date();
		dayToShowAllTasksFrom.setDate(now.getDate() - daysAgo);
		return dayToShowAllTasksFrom;
	};
	const getDurationText = (days: number) => {
		// Based on https://stackoverflow.com/a/22058912/132374
		let result = "";
		const years = Math.floor(days / 365);
		if (years) {
			result += `${years} year${years !== 1 ? "s" : ""} `;
		}
		const months = Math.floor((days - years * 365) / 30);
		if (months) {
			result += `${months} month${months !== 1 ? "s" : ""} `;
		}
		const wholeDays = Math.floor(days);
		if (wholeDays) {
			result += `${wholeDays} day${wholeDays !== 1 ? "s" : ""} `;
		}
		return result ? `Up to ${result} ago` : "";
	};
	const sliderChanged = (sliderSelection: number) => {
		const daysAgo = Math.pow(sliderSelection, 1.5) / 10;
		if (Math.floor(daysAgo) < 1) {
			setSliderLabel("None");
			dispatch(setPastTaskAsOfDate(null));
		} else if (sliderSelection > 95) {
			setSliderLabel("All");
			dispatch(setPastTaskAsOfDate(new Date(1980, 1)));
		} else {
			setSliderLabel(getDurationText(daysAgo));
			dispatch(setPastTaskAsOfDate(getDateInPast(daysAgo)));
		}
	};

	return (
		<SliderContainer className={props.className}>
			<SliderLabel style={{ fontWeight: "bold" }}>Completed tasks:</SliderLabel>
			<Slider
				reverse={true}
				onChange={sliderChanged}
				tooltipVisible={false}
				className="margin-left-medium"
				style={{ width: "150px" }}
			/>
			<SliderLabel className="margin-left-medium">{sliderLabel}</SliderLabel>
		</SliderContainer>
	);
};
const SliderContainer = styled(RowAlignedCenter)`
	min-width: 450px;
`;
const SliderLabel = styled.div`
	color: white;
	font-size: 0.8em;
`;
export default PastTasksSlider;
