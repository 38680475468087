import { DarkPagePanel, PageHeader } from "../../../ui-util/StyledComponents";
import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Link, Route, withRouter, RouteComponentProps } from "react-router-dom";
import ThemeAdminList from "./ThemeAdminList";
import ThemeAdminForm from "./ThemeAdminForm";

type ThemeAdminPageProps = RouteComponentProps;
const ThemeAdminPage = (props: ThemeAdminPageProps) => {
	const isEditing = !props.location.pathname.endsWith("admin/themes");
	return (
		<DarkPagePanel>
			<PageHeader>
				<h2>Theme Admin</h2>
				{!isEditing && (
					<Link to="/admin/themes/create">
						<Button shape="circle" icon={<PlusOutlined />} />
					</Link>
				)}
			</PageHeader>

			<Route exact path="/admin/themes" component={ThemeAdminList} />
			<Route path="/admin/themes/create" component={ThemeAdminForm} />
			<Route path="/admin/themes/edit/:themeId" component={ThemeAdminForm} />
		</DarkPagePanel>
	);
};
export default withRouter(ThemeAdminPage);
