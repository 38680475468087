/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:e4fd81c3-5158-4f53-b2bf-95150526b1a0",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_GpbLtB9yT",
    "aws_user_pools_web_client_id": "4qhvtc2ehr016npf9abmkvapps",
    "oauth": {
        "domain": "code-carpenter-prod.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.code-carpenter.com/",
        "redirectSignOut": "https://www.code-carpenter.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://x7feve4o3bb3dp5cibho2l3bmi.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "code-carpenter-hosting-bucket-prod",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://dur6v35lj69ft.cloudfront.net"
};


export default awsmobile;
