import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { DarkPagePanel, PageHeader } from "../../ui-util/StyledComponents";

const ProfilePage = () => {
	const [name, setName] = useState("");

	useEffect(() => {
		Auth.currentSession().then((session) =>
			setName(session.getIdToken().payload.given_name)
		);
	}, []);

	return (
		<DarkPagePanel>
			<PageHeader>
				<h2>Profile</h2>
			</PageHeader>

			{name && (
				<div>Welcome, {name}. User profile and settings coming soon.</div>
			)}
		</DarkPagePanel>
	);
};
export default ProfilePage;
