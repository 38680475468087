import { AnyAction, Reducer } from "redux";
import { CreateProjectInput } from "../../API";
import { ProjectActionNames } from "../actions/projects";

export type ProjectInState = CreateProjectInput & {
	version?: number;
};

export interface ProjectState {
	projectList: ProjectInState[];
	selectedProjectId: string | null;
}

const initialState: ProjectState = {
	projectList: [],
	selectedProjectId: null,
};

export const projects: Reducer<ProjectState> = (
	state: ProjectState = initialState,
	action: AnyAction
) => {
	switch (action.type) {
		case ProjectActionNames.SetProjects:
			return {
				...state,
				projectList: action.projects,
			};

		case ProjectActionNames.UpdateProject:
			const updatedProjectId = action.project.id;
			return {
				...state,
				projectList: [
					...state.projectList.filter(
						(project) => project.id !== updatedProjectId
					),
					action.project,
				],
			};

		case ProjectActionNames.SelectProject:
			return {
				...state,
				selectedProjectId: action.projectId,
			};

		default:
			return state;
	}
};
