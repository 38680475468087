import { API, graphqlOperation } from "aws-amplify";
import { Dispatch } from "react";
import {
	CreateTaskInput,
	CreateTaskMutation,
	DeleteTaskInput,
	ListTasksQuery,
	ListTasksQueryVariables,
	UpdateTaskInput,
	UpdateTaskMutation,
} from "../API";
import { createTask, deleteTask, updateTask } from "../graphql/mutations";
import { listTasks } from "../graphql/queries";
import {
	addOrUpdateTask,
	appendTasksForProject,
	removeTask,
	setLoadingTasks,
	setTasksForProject,
	setTaskTypes,
} from "../store/actions/tasks";
import { TaskInState } from "../store/reducers/tasks";
import Notifications from "../ui-util/Notifications";
import Settings from "../ui-util/Settings";
import { TaskType } from "./TaskType";

export default class TaskApi {
	static async loadTasksForProject(
		projectId: string,
		dispatch: Dispatch<any>
	): Promise<void> {
		dispatch(setLoadingTasks(true));
		dispatch(setTasksForProject(projectId, []));
		document.getElementById("pageContent").scrollTo(0, 0);
		const hideMessage = Notifications.showLoadingMessage("Loading...");
		const tasks = await TaskApi.loadTasks(projectId);
		dispatch(appendTasksForProject(projectId, tasks));
		hideMessage();
		dispatch(setLoadingTasks(false));
	}

	static loadTaskTypes(dispatch: Dispatch<any>): void {
		const getIconUrl = (name: string) =>
			`/images/task-type-icons/small/type-${name}-small.png`;
		const taskTypes: TaskType[] = [
			{
				id: "1",
				name: "Feature",
				iconUrl: getIconUrl("feature"),
			},
			{
				id: "2",
				name: "Task",
				iconUrl: getIconUrl("task"),
			},
			{
				id: "3",
				name: "Bug",
				iconUrl: getIconUrl("bug"),
			},
			{
				id: "4",
				name: "Testing",
				iconUrl: getIconUrl("testing"),
			},
			{
				id: "5",
				name: "Unit testing",
				iconUrl: getIconUrl("unit-testing"),
			},
			{
				id: "6",
				name: "Communication",
				iconUrl: getIconUrl("comm"),
			},
		];
		dispatch(setTaskTypes(taskTypes));
	}

	static async loadTasks(projectId: string): Promise<TaskInState[]> {
		let projectTasks: TaskInState[] = [];
		await this.loadProjectTasksByPage(projectId, (page: TaskInState[]) => {
			projectTasks = [...projectTasks, ...page];
		});
		return projectTasks;
	}

	private static async loadProjectTasksByPage(
		projectId: string,
		onPageLoaded: (page: TaskInState[]) => void
	): Promise<void> {
		try {
			let nextToken: string | null = null;
			do {
				const variables: ListTasksQueryVariables = {
					filter: { projectId: { eq: projectId } },
					limit: Settings.taskLoadingPageSize,
					nextToken,
				};
				const tasks = (await API.graphql(
					graphqlOperation(listTasks, variables)
				)) as { data: ListTasksQuery };
				onPageLoaded(this.parseTasks(tasks));
				nextToken = tasks.data.listTasks && tasks.data.listTasks.nextToken;
			} while (nextToken);
		} catch (e) {
			const errorMessage = `Sorry, the tasks couldn't be loaded for the project`;
			Notifications.handleError(errorMessage, e);
			throw new Error(errorMessage);
		}
	}

	private static parseTasks(tasks: { data: ListTasksQuery }): TaskInState[] {
		const loadedTasks =
			(tasks.data.listTasks && tasks.data.listTasks.items) || [];
		return loadedTasks
			.filter((task) => !!task)
			.map((task) => task as TaskInState);
	}

	static async createTask(
		task: TaskInState,
		title: string | null,
		description: string | null,
		dispatch: Dispatch<any>,
		isImporting = false
	): Promise<TaskInState> {
		try {
			const newTask: CreateTaskInput = {
				projectId: task.projectId,
				parentTaskId: task.parentTaskId,
				isExpanded: task.isExpanded,
				isFavorite: task.isFavorite,
				isUrgent: task.isUrgent,
				taskStatus: task.taskStatus,
				typeId: task.typeId,
				title,
				description,
				completionDate: task.completionDate,
				createdOn: task.createdOn,
			};
			const result = (await API.graphql(
				graphqlOperation(createTask, { input: newTask })
			)) as { data: CreateTaskMutation };
			const createdTask = result.data.createTask;
			if (!createdTask) {
				throw new Error("Unable to retrieve the newly created task");
			}
			// Prevent tasks from being removed and re-added to the UI, by preserving the unsaved task ID
			(createdTask as TaskInState).unsavedTaskId = task.unsavedTaskId;
			dispatch(addOrUpdateTask(createdTask, task.unsavedTaskId));
			if (!isImporting) {
				Notifications.handleSuccess(`Task created`);
			}
			return createdTask;
		} catch (e) {
			if (isImporting) {
				throw e;
			}
			Notifications.handleError(`Sorry, the task couldn't be created`, e);
		}
	}

	static importTask(
		task: TaskInState,
		dispatch: Dispatch<any>
	): Promise<TaskInState> {
		return TaskApi.createTask(
			task,
			task.title,
			task.description,
			dispatch,
			true
		);
	}

	static async updateTask(
		task: TaskInState,
		dispatch: Dispatch<any>,
		updateTaskInput: Partial<UpdateTaskInput>
	) {
		try {
			const input: UpdateTaskInput = {
				id: task.id as string,
				expectedVersion: task.version as number,
				...updateTaskInput,
			};
			const result = (await API.graphql(
				graphqlOperation(updateTask, { input })
			)) as { data: UpdateTaskMutation };
			const updatedTask = result.data.updateTask;
			if (!updatedTask) {
				throw new Error("Unable to retrieve the updated task");
			}
			delete updatedTask.owner;
			dispatch(addOrUpdateTask(updatedTask as TaskInState));
		} catch (e) {
			Notifications.handleError(`Sorry, the task couldn't be updated`, e);
		}
	}

	static async deleteTask(task: TaskInState, dispatch: Dispatch<any>) {
		try {
			if (task.id) {
				const input: DeleteTaskInput = {
					id: task.id,
					expectedVersion: task.version as number,
				};
				await API.graphql(graphqlOperation(deleteTask, { input }));
			}
			dispatch(removeTask(task));
		} catch (e) {
			Notifications.handleError(`Sorry, the task couldn't be deleted`, e);
		}
	}
}
