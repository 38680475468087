import React, { ReactElement } from "react";
import { useDispatch } from "react-redux";
import TaskApi from "../../../graphql-util/TaskApi";
import Tasks from "../../../graphql-util/Tasks";
import { TaskInState } from "../../../store/reducers/tasks";
import Settings from "../../../ui-util/Settings";
import { CheckSquareTwoTone } from "@ant-design/icons/lib";

type StatusIcon = {
	icon: ReactElement;
	tooltip: string;
};

const notCompletedIcon: StatusIcon = {
	icon: <CheckSquareTwoTone twoToneColor="silver" />,
	tooltip: "Not Completed",
};
const completeIcon: StatusIcon = {
	icon: <CheckSquareTwoTone twoToneColor="#52c41a" />,
	tooltip: "Complete",
};

type LeafIconProps = {
	task: TaskInState;
	className?: string;
};

const CompletionIcon = (props: LeafIconProps) => {
	const dispatch = useDispatch();

	const task = props.task;
	const isSubTask = Tasks.isSubTask(task);
	const isTaskComplete = Tasks.isComplete(task);

	const toggleCompletion = (isCompleted: boolean) => {
		TaskApi.updateTask(props.task, dispatch, {
			completionDate: isCompleted ? new Date().getTime() : null,
			taskStatus: isCompleted ? null : props.task.taskStatus,
		});
	};

	const isComplete = () => {
		return Tasks.isComplete(props.task);
	};

	const determineOpacity = () => {
		if (task.isHovered) {
			return 0.8;
		} else if (isTaskComplete) {
			return 0.5;
		} else {
			return 0;
		}
	};

	const createMainIcon = (statusIconToUse: ReactElement) => {
		const opacity = determineOpacity();
		if (!isSubTask && opacity === 0) {
			return <></>;
		}
		return (
			<div
				className={props.className}
				style={{
					fontSize: Settings.mainIconSize,
					opacity: determineOpacity(),
					cursor: "pointer",
					transition: "opacity 350ms",
				}}
				onClick={() => toggleCompletion(!isComplete())}
			>
				{statusIconToUse}
			</div>
		);
	};

	const completionIcon = createMainIcon(
		(Tasks.isComplete(task) ? completeIcon : notCompletedIcon).icon
	);

	if (!isSubTask) {
		return completionIcon;
	} else {
		return (
			<div style={{ fontSize: Settings.subTaskIconSize }}>{completionIcon}</div>
		);
	}
};
export default CompletionIcon;
