import { ProjectInState } from "../reducers/projects";

export enum ProjectActionNames {
	SetProjects = "SetProjects",
	UpdateProject = "UpdateProject",
	SelectProject = "SelectProject",
}

export const setProjects = (projects: ProjectInState[]) => ({
	type: ProjectActionNames.SetProjects,
	projects,
});

export const updateProjectInStore = (project: ProjectInState) => ({
	type: ProjectActionNames.UpdateProject,
	project,
});

export const selectProject = (projectId: string | null) => ({
	type: ProjectActionNames.SelectProject,
	projectId,
});
