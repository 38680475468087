import { Button, message } from "antd";
import React from "react";
import Settings from "./Settings";
import { UndoAction } from "./UndoActions";
import { MessageType } from "antd/lib/message";

message.config({
	top: 75,
});

export default class Notifications {
	static handleSuccess(messageText: string, undoAction?: UndoAction): void {
		if (!undoAction) {
			message.success(messageText);
		} else {
			const content = (
				<>
					{messageText} -{" "}
					<Button type="link" onClick={undoAction.actionToUndo}>
						Undo
					</Button>
				</>
			);
			message.success(content, Settings.undoNotificationDuration);
		}
	}

	static showLoadingMessage(messageText: string): MessageType {
		return message.loading(messageText, 0);
	}

	static handleError(errorMessage: string, error: any): void {
		message.error(errorMessage, 7);
		this.logInConsole(errorMessage, error);
		throw errorMessage;
	}

	private static logInConsole(errorMessage: string, error: any): void {
		console.error(errorMessage, error);
		if (error.errors && Array.isArray(error.errors)) {
			error.errors.forEach((subError: any) => {
				console.error(`\t${subError.errorType}: ${subError.message}`);
			});
		}
	}
}
