import React from "react";
import { RowJustifiedCenter } from "../../ui-util/StyledComponents";
import {
	ClockCircleOutlined,
	ExpandAltOutlined,
	EyeOutlined,
	ShrinkOutlined,
} from "@ant-design/icons/lib";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/reducers";
import {
	setPastTaskAsOfDate,
	setPeekCollapsedTasks,
	setShowPastTasksSlider,
} from "../../store/actions/settings";
import Tasks from "../../graphql-util/Tasks";
import TaskApi from "../../graphql-util/TaskApi";
import UiUtil from "../../ui-util/UiUtil";
import PastTasks from "./PastTasksSlider";
import Settings from "../../ui-util/Settings";

type ToolbarProps = {
	className?: string;
};
const Toolbar = (props: ToolbarProps) => {
	const dispatch = useDispatch();
	const { peekCollapsedTasks, showPastTasksSlider, allTasks } = useSelector(
		(state: StoreState) => ({
			peekCollapsedTasks: state.settings.peekCollapsedTasks,
			showPastTasksSlider: state.settings.showPastTasksSlider,
			allTasks: state.tasks.allTasks,
		})
	);

	const togglePeekCollapsedTasks = () => {
		dispatch(setPeekCollapsedTasks(!peekCollapsedTasks));
	};
	const collapseAll = () => {
		allTasks
			.filter((task) => !Tasks.isSubTask(task) && !task.isCollapsed)
			.forEach((task) => {
				TaskApi.updateTask(task, dispatch, { isCollapsed: true });
			});
	};
	const expandAll = () => {
		allTasks
			.filter((task) => !Tasks.isSubTask(task) && task.isCollapsed)
			.forEach((task) => {
				TaskApi.updateTask(task, dispatch, { isCollapsed: false });
			});
	};
	const toggleShowPastTasksSlider = () => {
		const showSlider = !showPastTasksSlider;
		dispatch(setShowPastTasksSlider(showSlider));
		if (!showSlider) {
			dispatch(setPastTaskAsOfDate(null));
		}
	};
	const commonConfig = Settings.headerIconConfig;

	return (
		<RowJustifiedCenter className={props.className}>
			{UiUtil.createIcon({
				...commonConfig,
				tooltip: "Peek into collapsed tasks",
				disableTooltip: "Disable peek",
				icon: <EyeOutlined />,
				isSelected: peekCollapsedTasks,
				onClick: togglePeekCollapsedTasks,
			})}
			{UiUtil.createIcon({
				...commonConfig,
				tooltip: "Collapse all",
				icon: <ShrinkOutlined />,
				requireConfirmation: true,
				onClick: collapseAll,
			})}
			{UiUtil.createIcon({
				...commonConfig,
				tooltip: "Expand all",
				icon: <ExpandAltOutlined />,
				requireConfirmation: true,
				onClick: expandAll,
			})}
			{UiUtil.createIcon({
				...commonConfig,
				tooltip: "Show completed tasks...",
				disableTooltip: "Stop showing completed tasks",
				icon: <ClockCircleOutlined />,
				leftSpacing: true,
				isSelected: showPastTasksSlider,
				onClick: toggleShowPastTasksSlider,
			})}
			{showPastTasksSlider && <PastTasks className="margin-left-small" />}
		</RowJustifiedCenter>
	);
};
export default Toolbar;
