/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      name
      isFavorite
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      name
      isFavorite
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      name
      isFavorite
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      projectId
      parentTaskId
      isExpanded
      isCollapsed
      isFavorite
      isUrgent
      taskStatus
      typeId
      title
      description
      completionDate
      createdOn
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      projectId
      parentTaskId
      isExpanded
      isCollapsed
      isFavorite
      isUrgent
      taskStatus
      typeId
      title
      description
      completionDate
      createdOn
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      projectId
      parentTaskId
      isExpanded
      isCollapsed
      isFavorite
      isUrgent
      taskStatus
      typeId
      title
      description
      completionDate
      createdOn
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const createSharedTheme = /* GraphQL */ `
  mutation CreateSharedTheme(
    $input: CreateSharedThemeInput!
    $condition: ModelSharedThemeConditionInput
  ) {
    createSharedTheme(input: $input, condition: $condition) {
      id
      name
      isDefault
      backgrounds {
        nextToken
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const updateSharedTheme = /* GraphQL */ `
  mutation UpdateSharedTheme(
    $input: UpdateSharedThemeInput!
    $condition: ModelSharedThemeConditionInput
  ) {
    updateSharedTheme(input: $input, condition: $condition) {
      id
      name
      isDefault
      backgrounds {
        nextToken
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const deleteSharedTheme = /* GraphQL */ `
  mutation DeleteSharedTheme(
    $input: DeleteSharedThemeInput!
    $condition: ModelSharedThemeConditionInput
  ) {
    deleteSharedTheme(input: $input, condition: $condition) {
      id
      name
      isDefault
      backgrounds {
        nextToken
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const createSharedBackground = /* GraphQL */ `
  mutation CreateSharedBackground(
    $input: CreateSharedBackgroundInput!
    $condition: ModelSharedBackgroundConditionInput
  ) {
    createSharedBackground(input: $input, condition: $condition) {
      id
      url
      title
      theme {
        id
        name
        isDefault
        createdAt
        updatedAt
        version
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const updateSharedBackground = /* GraphQL */ `
  mutation UpdateSharedBackground(
    $input: UpdateSharedBackgroundInput!
    $condition: ModelSharedBackgroundConditionInput
  ) {
    updateSharedBackground(input: $input, condition: $condition) {
      id
      url
      title
      theme {
        id
        name
        isDefault
        createdAt
        updatedAt
        version
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const deleteSharedBackground = /* GraphQL */ `
  mutation DeleteSharedBackground(
    $input: DeleteSharedBackgroundInput!
    $condition: ModelSharedBackgroundConditionInput
  ) {
    deleteSharedBackground(input: $input, condition: $condition) {
      id
      url
      title
      theme {
        id
        name
        isDefault
        createdAt
        updatedAt
        version
      }
      createdAt
      updatedAt
      version
    }
  }
`;
