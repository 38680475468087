import { ArrowRightOutlined, DeleteOutlined } from "@ant-design/icons";
import React from "react";
import { useDispatch } from "react-redux";
import TaskApi from "../../../graphql-util/TaskApi";
import { TaskInState } from "../../../store/reducers/tasks";
import Notifications from "../../../ui-util/Notifications";
import { removeTask } from "../../../store/actions/tasks";
import UiUtil from "../../../ui-util/UiUtil";

type SubTaskMenuProps = {
	task: TaskInState;
};
const SubTaskMenuIcons = (props: SubTaskMenuProps) => {
	const dispatch = useDispatch();

	const task = props.task;

	const expandSubTask = async () => {
		await TaskApi.updateTask(task, dispatch, {
			isExpanded: true,
			isCollapsed: false,
		});
	};
	const deleteSubTask = async () => {
		await TaskApi.deleteTask(task, dispatch);
		Notifications.handleSuccess("Sub-task deleted");
	};
	const removeUnsavedTask = () => dispatch(removeTask(props.task));

	const createIcons = () => {
		const isSaved = !!props.task.id;
		const canExpand = !!task.id;

		if (isSaved) {
			if (task.isHovered) {
				const icons = [];
				if (canExpand) {
					icons.push(
						UiUtil.createIcon({
							tooltip: "Expand",
							icon: <ArrowRightOutlined />,
							onClick: expandSubTask,
						})
					);
				}
				icons.push(
					UiUtil.createIcon({
						tooltip: "Delete",
						icon: <DeleteOutlined />,
						onClick: deleteSubTask,
					})
				);
				return icons;
			} else {
				return [];
			}
		} else {
			return [
				UiUtil.createIcon({
					tooltip: "Delete this draft",
					icon: <DeleteOutlined />,
					isDangerIcon: true,
					onClick: removeUnsavedTask,
				}),
			];
		}
	};

	return <>{createIcons()}</>;
};
export default SubTaskMenuIcons;
