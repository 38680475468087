/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateProjectInput = {
  id?: string | null,
  name: string,
  isFavorite?: boolean | null,
};

export type ModelProjectConditionInput = {
  name?: ModelStringInput | null,
  isFavorite?: ModelBooleanInput | null,
  and?: Array< ModelProjectConditionInput | null > | null,
  or?: Array< ModelProjectConditionInput | null > | null,
  not?: ModelProjectConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Project = {
  __typename: "Project",
  id?: string,
  name?: string,
  isFavorite?: boolean | null,
  createdAt?: string,
  updatedAt?: string,
  version?: number,
  owner?: string | null,
};

export type UpdateProjectInput = {
  id: string,
  name?: string | null,
  isFavorite?: boolean | null,
  expectedVersion: number,
};

export type DeleteProjectInput = {
  id: string,
  expectedVersion: number,
};

export type CreateTaskInput = {
  id?: string | null,
  projectId: string,
  parentTaskId?: string | null,
  isExpanded: boolean,
  isCollapsed?: boolean | null,
  isFavorite?: boolean | null,
  isUrgent?: boolean | null,
  taskStatus?: TaskStatus | null,
  typeId?: string | null,
  title?: string | null,
  description?: string | null,
  completionDate?: number | null,
  createdOn: number,
};

export enum TaskStatus {
  ON_HOLD = "ON_HOLD",
}


export type ModelTaskConditionInput = {
  projectId?: ModelStringInput | null,
  parentTaskId?: ModelStringInput | null,
  isExpanded?: ModelBooleanInput | null,
  isCollapsed?: ModelBooleanInput | null,
  isFavorite?: ModelBooleanInput | null,
  isUrgent?: ModelBooleanInput | null,
  taskStatus?: ModelTaskStatusInput | null,
  typeId?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  completionDate?: ModelFloatInput | null,
  createdOn?: ModelFloatInput | null,
  and?: Array< ModelTaskConditionInput | null > | null,
  or?: Array< ModelTaskConditionInput | null > | null,
  not?: ModelTaskConditionInput | null,
};

export type ModelTaskStatusInput = {
  eq?: TaskStatus | null,
  ne?: TaskStatus | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Task = {
  __typename: "Task",
  id?: string,
  projectId?: string,
  parentTaskId?: string | null,
  isExpanded?: boolean,
  isCollapsed?: boolean | null,
  isFavorite?: boolean | null,
  isUrgent?: boolean | null,
  taskStatus?: TaskStatus | null,
  typeId?: string | null,
  title?: string | null,
  description?: string | null,
  completionDate?: number | null,
  createdOn?: number,
  createdAt?: string,
  updatedAt?: string,
  version?: number,
  owner?: string | null,
};

export type UpdateTaskInput = {
  id: string,
  projectId?: string | null,
  parentTaskId?: string | null,
  isExpanded?: boolean | null,
  isCollapsed?: boolean | null,
  isFavorite?: boolean | null,
  isUrgent?: boolean | null,
  taskStatus?: TaskStatus | null,
  typeId?: string | null,
  title?: string | null,
  description?: string | null,
  completionDate?: number | null,
  createdOn?: number | null,
  expectedVersion: number,
};

export type DeleteTaskInput = {
  id: string,
  expectedVersion: number,
};

export type CreateSharedThemeInput = {
  id?: string | null,
  name: string,
  isDefault: boolean,
};

export type ModelSharedThemeConditionInput = {
  name?: ModelStringInput | null,
  isDefault?: ModelBooleanInput | null,
  and?: Array< ModelSharedThemeConditionInput | null > | null,
  or?: Array< ModelSharedThemeConditionInput | null > | null,
  not?: ModelSharedThemeConditionInput | null,
};

export type SharedTheme = {
  __typename: "SharedTheme",
  id?: string,
  name?: string,
  isDefault?: boolean,
  backgrounds?: ModelSharedBackgroundConnection,
  createdAt?: string,
  updatedAt?: string,
  version?: number,
};

export type ModelSharedBackgroundConnection = {
  __typename: "ModelSharedBackgroundConnection",
  items?:  Array<SharedBackground | null > | null,
  nextToken?: string | null,
};

export type SharedBackground = {
  __typename: "SharedBackground",
  id?: string,
  url?: string,
  title?: string | null,
  theme?: SharedTheme,
  createdAt?: string,
  updatedAt?: string,
  version?: number,
};

export type UpdateSharedThemeInput = {
  id: string,
  name?: string | null,
  isDefault?: boolean | null,
  expectedVersion: number,
};

export type DeleteSharedThemeInput = {
  id: string,
  expectedVersion: number,
};

export type CreateSharedBackgroundInput = {
  id?: string | null,
  url: string,
  title?: string | null,
  sharedBackgroundThemeId?: string | null,
};

export type ModelSharedBackgroundConditionInput = {
  url?: ModelStringInput | null,
  title?: ModelStringInput | null,
  and?: Array< ModelSharedBackgroundConditionInput | null > | null,
  or?: Array< ModelSharedBackgroundConditionInput | null > | null,
  not?: ModelSharedBackgroundConditionInput | null,
};

export type UpdateSharedBackgroundInput = {
  id: string,
  url?: string | null,
  title?: string | null,
  expectedVersion: number,
  sharedBackgroundThemeId?: string | null,
};

export type DeleteSharedBackgroundInput = {
  id: string,
  expectedVersion: number,
};

export type ModelProjectFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  isFavorite?: ModelBooleanInput | null,
  and?: Array< ModelProjectFilterInput | null > | null,
  or?: Array< ModelProjectFilterInput | null > | null,
  not?: ModelProjectFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelProjectConnection = {
  __typename: "ModelProjectConnection",
  items?:  Array<Project | null > | null,
  nextToken?: string | null,
};

export type ModelTaskFilterInput = {
  id?: ModelIDInput | null,
  projectId?: ModelStringInput | null,
  parentTaskId?: ModelStringInput | null,
  isExpanded?: ModelBooleanInput | null,
  isCollapsed?: ModelBooleanInput | null,
  isFavorite?: ModelBooleanInput | null,
  isUrgent?: ModelBooleanInput | null,
  taskStatus?: ModelTaskStatusInput | null,
  typeId?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  completionDate?: ModelFloatInput | null,
  createdOn?: ModelFloatInput | null,
  and?: Array< ModelTaskFilterInput | null > | null,
  or?: Array< ModelTaskFilterInput | null > | null,
  not?: ModelTaskFilterInput | null,
};

export type ModelTaskConnection = {
  __typename: "ModelTaskConnection",
  items?:  Array<Task | null > | null,
  nextToken?: string | null,
};

export type ModelSharedThemeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  isDefault?: ModelBooleanInput | null,
  and?: Array< ModelSharedThemeFilterInput | null > | null,
  or?: Array< ModelSharedThemeFilterInput | null > | null,
  not?: ModelSharedThemeFilterInput | null,
};

export type ModelSharedThemeConnection = {
  __typename: "ModelSharedThemeConnection",
  items?:  Array<SharedTheme | null > | null,
  nextToken?: string | null,
};

export type ModelSharedBackgroundFilterInput = {
  id?: ModelIDInput | null,
  url?: ModelStringInput | null,
  title?: ModelStringInput | null,
  and?: Array< ModelSharedBackgroundFilterInput | null > | null,
  or?: Array< ModelSharedBackgroundFilterInput | null > | null,
  not?: ModelSharedBackgroundFilterInput | null,
};

export type CreateProjectMutationVariables = {
  input?: CreateProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type CreateProjectMutation = {
  createProject?:  {
    __typename: "Project",
    id: string,
    name: string,
    isFavorite?: boolean | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    owner?: string | null,
  } | null,
};

export type UpdateProjectMutationVariables = {
  input?: UpdateProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type UpdateProjectMutation = {
  updateProject?:  {
    __typename: "Project",
    id: string,
    name: string,
    isFavorite?: boolean | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    owner?: string | null,
  } | null,
};

export type DeleteProjectMutationVariables = {
  input?: DeleteProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type DeleteProjectMutation = {
  deleteProject?:  {
    __typename: "Project",
    id: string,
    name: string,
    isFavorite?: boolean | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    owner?: string | null,
  } | null,
};

export type CreateTaskMutationVariables = {
  input?: CreateTaskInput,
  condition?: ModelTaskConditionInput | null,
};

export type CreateTaskMutation = {
  createTask?:  {
    __typename: "Task",
    id: string,
    projectId: string,
    parentTaskId?: string | null,
    isExpanded: boolean,
    isCollapsed?: boolean | null,
    isFavorite?: boolean | null,
    isUrgent?: boolean | null,
    taskStatus?: TaskStatus | null,
    typeId?: string | null,
    title?: string | null,
    description?: string | null,
    completionDate?: number | null,
    createdOn: number,
    createdAt: string,
    updatedAt: string,
    version: number,
    owner?: string | null,
  } | null,
};

export type UpdateTaskMutationVariables = {
  input?: UpdateTaskInput,
  condition?: ModelTaskConditionInput | null,
};

export type UpdateTaskMutation = {
  updateTask?:  {
    __typename: "Task",
    id: string,
    projectId: string,
    parentTaskId?: string | null,
    isExpanded: boolean,
    isCollapsed?: boolean | null,
    isFavorite?: boolean | null,
    isUrgent?: boolean | null,
    taskStatus?: TaskStatus | null,
    typeId?: string | null,
    title?: string | null,
    description?: string | null,
    completionDate?: number | null,
    createdOn: number,
    createdAt: string,
    updatedAt: string,
    version: number,
    owner?: string | null,
  } | null,
};

export type DeleteTaskMutationVariables = {
  input?: DeleteTaskInput,
  condition?: ModelTaskConditionInput | null,
};

export type DeleteTaskMutation = {
  deleteTask?:  {
    __typename: "Task",
    id: string,
    projectId: string,
    parentTaskId?: string | null,
    isExpanded: boolean,
    isCollapsed?: boolean | null,
    isFavorite?: boolean | null,
    isUrgent?: boolean | null,
    taskStatus?: TaskStatus | null,
    typeId?: string | null,
    title?: string | null,
    description?: string | null,
    completionDate?: number | null,
    createdOn: number,
    createdAt: string,
    updatedAt: string,
    version: number,
    owner?: string | null,
  } | null,
};

export type CreateSharedThemeMutationVariables = {
  input?: CreateSharedThemeInput,
  condition?: ModelSharedThemeConditionInput | null,
};

export type CreateSharedThemeMutation = {
  createSharedTheme?:  {
    __typename: "SharedTheme",
    id: string,
    name: string,
    isDefault: boolean,
    backgrounds?:  {
      __typename: "ModelSharedBackgroundConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type UpdateSharedThemeMutationVariables = {
  input?: UpdateSharedThemeInput,
  condition?: ModelSharedThemeConditionInput | null,
};

export type UpdateSharedThemeMutation = {
  updateSharedTheme?:  {
    __typename: "SharedTheme",
    id: string,
    name: string,
    isDefault: boolean,
    backgrounds?:  {
      __typename: "ModelSharedBackgroundConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type DeleteSharedThemeMutationVariables = {
  input?: DeleteSharedThemeInput,
  condition?: ModelSharedThemeConditionInput | null,
};

export type DeleteSharedThemeMutation = {
  deleteSharedTheme?:  {
    __typename: "SharedTheme",
    id: string,
    name: string,
    isDefault: boolean,
    backgrounds?:  {
      __typename: "ModelSharedBackgroundConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type CreateSharedBackgroundMutationVariables = {
  input?: CreateSharedBackgroundInput,
  condition?: ModelSharedBackgroundConditionInput | null,
};

export type CreateSharedBackgroundMutation = {
  createSharedBackground?:  {
    __typename: "SharedBackground",
    id: string,
    url: string,
    title?: string | null,
    theme?:  {
      __typename: "SharedTheme",
      id: string,
      name: string,
      isDefault: boolean,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type UpdateSharedBackgroundMutationVariables = {
  input?: UpdateSharedBackgroundInput,
  condition?: ModelSharedBackgroundConditionInput | null,
};

export type UpdateSharedBackgroundMutation = {
  updateSharedBackground?:  {
    __typename: "SharedBackground",
    id: string,
    url: string,
    title?: string | null,
    theme?:  {
      __typename: "SharedTheme",
      id: string,
      name: string,
      isDefault: boolean,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type DeleteSharedBackgroundMutationVariables = {
  input?: DeleteSharedBackgroundInput,
  condition?: ModelSharedBackgroundConditionInput | null,
};

export type DeleteSharedBackgroundMutation = {
  deleteSharedBackground?:  {
    __typename: "SharedBackground",
    id: string,
    url: string,
    title?: string | null,
    theme?:  {
      __typename: "SharedTheme",
      id: string,
      name: string,
      isDefault: boolean,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type GetProjectQueryVariables = {
  id?: string,
};

export type GetProjectQuery = {
  getProject?:  {
    __typename: "Project",
    id: string,
    name: string,
    isFavorite?: boolean | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    owner?: string | null,
  } | null,
};

export type ListProjectsQueryVariables = {
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProjectsQuery = {
  listProjects?:  {
    __typename: "ModelProjectConnection",
    items?:  Array< {
      __typename: "Project",
      id: string,
      name: string,
      isFavorite?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      owner?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetTaskQueryVariables = {
  id?: string,
};

export type GetTaskQuery = {
  getTask?:  {
    __typename: "Task",
    id: string,
    projectId: string,
    parentTaskId?: string | null,
    isExpanded: boolean,
    isCollapsed?: boolean | null,
    isFavorite?: boolean | null,
    isUrgent?: boolean | null,
    taskStatus?: TaskStatus | null,
    typeId?: string | null,
    title?: string | null,
    description?: string | null,
    completionDate?: number | null,
    createdOn: number,
    createdAt: string,
    updatedAt: string,
    version: number,
    owner?: string | null,
  } | null,
};

export type ListTasksQueryVariables = {
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTasksQuery = {
  listTasks?:  {
    __typename: "ModelTaskConnection",
    items?:  Array< {
      __typename: "Task",
      id: string,
      projectId: string,
      parentTaskId?: string | null,
      isExpanded: boolean,
      isCollapsed?: boolean | null,
      isFavorite?: boolean | null,
      isUrgent?: boolean | null,
      taskStatus?: TaskStatus | null,
      typeId?: string | null,
      title?: string | null,
      description?: string | null,
      completionDate?: number | null,
      createdOn: number,
      createdAt: string,
      updatedAt: string,
      version: number,
      owner?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetSharedThemeQueryVariables = {
  id?: string,
};

export type GetSharedThemeQuery = {
  getSharedTheme?:  {
    __typename: "SharedTheme",
    id: string,
    name: string,
    isDefault: boolean,
    backgrounds?:  {
      __typename: "ModelSharedBackgroundConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type ListSharedThemesQueryVariables = {
  filter?: ModelSharedThemeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSharedThemesQuery = {
  listSharedThemes?:  {
    __typename: "ModelSharedThemeConnection",
    items?:  Array< {
      __typename: "SharedTheme",
      id: string,
      name: string,
      isDefault: boolean,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetSharedBackgroundQueryVariables = {
  id?: string,
};

export type GetSharedBackgroundQuery = {
  getSharedBackground?:  {
    __typename: "SharedBackground",
    id: string,
    url: string,
    title?: string | null,
    theme?:  {
      __typename: "SharedTheme",
      id: string,
      name: string,
      isDefault: boolean,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type ListSharedBackgroundsQueryVariables = {
  filter?: ModelSharedBackgroundFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSharedBackgroundsQuery = {
  listSharedBackgrounds?:  {
    __typename: "ModelSharedBackgroundConnection",
    items?:  Array< {
      __typename: "SharedBackground",
      id: string,
      url: string,
      title?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type OnCreateProjectSubscriptionVariables = {
  owner?: string,
};

export type OnCreateProjectSubscription = {
  onCreateProject?:  {
    __typename: "Project",
    id: string,
    name: string,
    isFavorite?: boolean | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateProjectSubscriptionVariables = {
  owner?: string,
};

export type OnUpdateProjectSubscription = {
  onUpdateProject?:  {
    __typename: "Project",
    id: string,
    name: string,
    isFavorite?: boolean | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteProjectSubscriptionVariables = {
  owner?: string,
};

export type OnDeleteProjectSubscription = {
  onDeleteProject?:  {
    __typename: "Project",
    id: string,
    name: string,
    isFavorite?: boolean | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    owner?: string | null,
  } | null,
};

export type OnCreateTaskSubscriptionVariables = {
  owner?: string,
};

export type OnCreateTaskSubscription = {
  onCreateTask?:  {
    __typename: "Task",
    id: string,
    projectId: string,
    parentTaskId?: string | null,
    isExpanded: boolean,
    isCollapsed?: boolean | null,
    isFavorite?: boolean | null,
    isUrgent?: boolean | null,
    taskStatus?: TaskStatus | null,
    typeId?: string | null,
    title?: string | null,
    description?: string | null,
    completionDate?: number | null,
    createdOn: number,
    createdAt: string,
    updatedAt: string,
    version: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateTaskSubscriptionVariables = {
  owner?: string,
};

export type OnUpdateTaskSubscription = {
  onUpdateTask?:  {
    __typename: "Task",
    id: string,
    projectId: string,
    parentTaskId?: string | null,
    isExpanded: boolean,
    isCollapsed?: boolean | null,
    isFavorite?: boolean | null,
    isUrgent?: boolean | null,
    taskStatus?: TaskStatus | null,
    typeId?: string | null,
    title?: string | null,
    description?: string | null,
    completionDate?: number | null,
    createdOn: number,
    createdAt: string,
    updatedAt: string,
    version: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteTaskSubscriptionVariables = {
  owner?: string,
};

export type OnDeleteTaskSubscription = {
  onDeleteTask?:  {
    __typename: "Task",
    id: string,
    projectId: string,
    parentTaskId?: string | null,
    isExpanded: boolean,
    isCollapsed?: boolean | null,
    isFavorite?: boolean | null,
    isUrgent?: boolean | null,
    taskStatus?: TaskStatus | null,
    typeId?: string | null,
    title?: string | null,
    description?: string | null,
    completionDate?: number | null,
    createdOn: number,
    createdAt: string,
    updatedAt: string,
    version: number,
    owner?: string | null,
  } | null,
};

export type OnCreateSharedThemeSubscription = {
  onCreateSharedTheme?:  {
    __typename: "SharedTheme",
    id: string,
    name: string,
    isDefault: boolean,
    backgrounds?:  {
      __typename: "ModelSharedBackgroundConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnUpdateSharedThemeSubscription = {
  onUpdateSharedTheme?:  {
    __typename: "SharedTheme",
    id: string,
    name: string,
    isDefault: boolean,
    backgrounds?:  {
      __typename: "ModelSharedBackgroundConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnDeleteSharedThemeSubscription = {
  onDeleteSharedTheme?:  {
    __typename: "SharedTheme",
    id: string,
    name: string,
    isDefault: boolean,
    backgrounds?:  {
      __typename: "ModelSharedBackgroundConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnCreateSharedBackgroundSubscription = {
  onCreateSharedBackground?:  {
    __typename: "SharedBackground",
    id: string,
    url: string,
    title?: string | null,
    theme?:  {
      __typename: "SharedTheme",
      id: string,
      name: string,
      isDefault: boolean,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnUpdateSharedBackgroundSubscription = {
  onUpdateSharedBackground?:  {
    __typename: "SharedBackground",
    id: string,
    url: string,
    title?: string | null,
    theme?:  {
      __typename: "SharedTheme",
      id: string,
      name: string,
      isDefault: boolean,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnDeleteSharedBackgroundSubscription = {
  onDeleteSharedBackground?:  {
    __typename: "SharedBackground",
    id: string,
    url: string,
    title?: string | null,
    theme?:  {
      __typename: "SharedTheme",
      id: string,
      name: string,
      isDefault: boolean,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};
