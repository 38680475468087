import React, { CSSProperties } from "react";
import { useDispatch } from "react-redux";
import { UpdateTaskInput } from "../../../API";
import TaskApi from "../../../graphql-util/TaskApi";
import { TaskInState } from "../../../store/reducers/tasks";
import { StyledCompProps } from "../../../ui-util/StyledComponents";
import TextEditor from "../../../ui-util/TextEditor";

type TitleOrDescriptionProps = StyledCompProps & {
	className?: string;
	task: TaskInState;
	isTitle: boolean;
	editorTextStyle?: CSSProperties;
};
const TitleOrDescription = (props: TitleOrDescriptionProps) => {
	const dispatch = useDispatch();

	const task = props.task;
	const isTitle = props.isTitle;

	const editImmediately = !!task.editImmediately;

	const createOrUpdateTask = (text: string): void => {
		if (task.id) {
			const fieldUpdate: Partial<UpdateTaskInput> = isTitle
				? {title: text || null}
				: {description: text || null};
			TaskApi.updateTask(task, dispatch, fieldUpdate);
		} else {
			const title = isTitle ? text || null : null;
			const description = !isTitle ? text || null : null;
			TaskApi.createTask(task, title, description, dispatch);
		}
	};

	const asterisks = isTitle ? "***" : "*";
	const labelPlaceholder = `${asterisks}Click to add a ${
		isTitle ? "title" : "description"
	}${asterisks}`;
	const originalText = isTitle ? task.title || "" : task.description || "";
	return (
		<div className={`leaf-title-or-description ${props.className || ""}`}>
			<TextEditor
				labelPlaceholder={labelPlaceholder}
				editorPlaceholder={isTitle ? "Title" : "Description"}
				editorTextStyle={props.editorTextStyle}
				editImmediately={editImmediately && isTitle}
				originalText={originalText}
				onSave={(text: string) => createOrUpdateTask(text)}
			/>
		</div>
	);
};
export default TitleOrDescription;
