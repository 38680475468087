import { UndoAction } from "../../ui-util/UndoActions";

export enum SettingActionNames {
	ShowPastTasksSlider = "ShowPastTasksSlider",
	SetPeekCollapsedTasks = "SetPeekCollapsedTasks",
	SetPastTaskAsOfDate = "SetPastTaskAsOfDate",
	SetIsDragging = "SetIsDragging",
	AddUndoAction = "AddUndoAction",
	ClearUndoActions = "ClearUndoActions",
}

export const setPeekCollapsedTasks = (peekCollapsedTasks: boolean) => ({
	type: SettingActionNames.SetPeekCollapsedTasks,
	peekCollapsedTasks,
});

export const setShowPastTasksSlider = (showPastTasksSlider: boolean) => ({
	type: SettingActionNames.ShowPastTasksSlider,
	showPastTasksSlider,
});

export const setPastTaskAsOfDate = (date: Date | null) => ({
	type: SettingActionNames.SetPastTaskAsOfDate,
	date,
});

export const setIsDragging = (dragging: boolean) => ({
	type: SettingActionNames.SetIsDragging,
	dragging,
});

export const addUndoAction = (undoAction: UndoAction) => ({
	type: SettingActionNames.AddUndoAction,
	undoAction,
});

export const clearUndoActions = () => ({
	type: SettingActionNames.ClearUndoActions,
});
