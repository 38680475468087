import { TaskInState } from "../store/reducers/tasks";
import TaskApi from "../graphql-util/TaskApi";

export class ImportExportUtil {
	public static exportProject(allTasks: TaskInState[]): Blob {
		const json = JSON.stringify(allTasks);
		return new Blob([json], { type: "application/json" });
	}

	public static async importProject(
		projectId: string,
		tasks: TaskInState[],
		dispatch: any
	) {
		const originalToNewIdMap = new Map<string, string>();
		const importTask = async (task: TaskInState) => {
			task.projectId = projectId;
			const createdAt = (task as any).createdAt;
			task.createdOn =
				(!!createdAt && parseInt(createdAt, 10)) || task.createdOn;
			const importedTask = await TaskApi.importTask(task, dispatch);
			if (!task.id) {
				console.error(
					"Import stopped because this task doesn't have an ID:",
					task
				);
				throw new Error("A task doesn't have an ID");
			}
			if (task.id) {
				originalToNewIdMap.set(task.id, importedTask.id);
			}
		};

		// Import tasks without parents
		const tasksWithoutParents = tasks.filter((task) => !task.parentTaskId);
		for (const task of tasksWithoutParents) {
			await importTask(task);
		}
		tasks = tasks.filter((task) => !!task.parentTaskId);

		// Import tasks with parents
		let tasksInPreviousLoop = tasks.length;
		while (tasks.length > 0) {
			const idsOfImportedTasks = new Set<string>();
			for (const task of tasks) {
				if (originalToNewIdMap.has(task.parentTaskId)) {
					task.parentTaskId = originalToNewIdMap.get(task.parentTaskId);
					await importTask(task);
					idsOfImportedTasks.add(task.id);
				}
			}
			tasks = tasks.filter((task) => !idsOfImportedTasks.has(task.id));
			const tasksLeft = tasks.length;
			if (tasksLeft > 0 && tasksLeft === tasksInPreviousLoop) {
				console.log(`Tasks weren't able to be imported: `, tasks);
				console.log(`Map of old to new task IDs:`, originalToNewIdMap);
				throw new Error(
					`${tasksLeft} tasks weren't able to be imported because their parents couldn't be located`
				);
			}
			tasksInPreviousLoop = tasksLeft;
		}
	}
}
